import React from 'react';

import { Card, CardContent, CardMedia, Typography } from '@mui/material';

import styles from './Info.module.scss';
import { ListItem } from './ListItem/ListItem';

const Info: React.FC = () => (
    <div className={styles.Info}>
        <div className={styles.background}>
            <div className={styles.container}>
                <h3>Keine Lust auf hohe Provision?</h3>
                <h4>Wir unterstützen Sie mit dauerhaft günstigen Preisen!</h4>
            </div>
        </div>
        <div className={styles.about}>
            <h1>Über uns</h1>
            <p>
                BestellEck ist eine Plattform, die hungrige Kunden mit ihren Lieblingsrestaurants verbindet. Dabei
                versuchen wir, die Gastronomie mit fairen Preisen nachhaltig zu unterstützen und deren Digitalisierung
                einfach zu gestalten.
            </p>
        </div>
        <div className={styles.whyContainer}>
            <h1>Warum BestellEck?</h1>
            <div className={styles.instructions}>
                <Card className={styles.card}>
                    <CardMedia className={styles.media} image="/assets/pizza.jpg" title="Pizzabäcker" />
                    <CardContent>
                        <Typography variant="h5">Günstig</Typography>
                        <Typography>
                            Keine Provision und ein dauerhafter Festpreis sorgen für kalkulierbare Ausgaben.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={styles.card}>
                    <CardMedia className={styles.media} image="/assets/barista.jpg" title="Barista" />
                    <CardContent>
                        <Typography variant="h5">Einfach</Typography>
                        <Typography>
                            Alles was ein Restaurant benötigt ist ein Smartphone, auf dem die Bestellungen empfangen
                            werden können.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={styles.card}>
                    <CardMedia
                        className={styles.media}
                        image="/assets/delivery.jpg"
                        title="Vorbereitung einer Lieferung"
                    />
                    <CardContent>
                        <Typography variant="h5">Volle Kontrolle</Typography>
                        <Typography>
                            Bei BestellEck behalten Sie die volle Kontrolle, sowohl für Bestellungen als auch
                            Bezahlungen. Das Geld landet direkt bei Ihnen und Sie müssen nicht warten, bis es ausgezahlt
                            wird.
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
        <div className={styles.about}>
            <h1>Unsere Leistungen im Detail</h1>
            <div className={styles.list}>
                <ListItem>Keine Provision</ListItem>
                <ListItem>Keine Anmeldegebühr</ListItem>
                <ListItem>Monatlich kündbar</ListItem>
                <ListItem>Online-Zahlungen über PayPal, Kreditkarte, ApplePay und GooglePay</ListItem>
                <ListItem>
                    Die Anwendung für das Restaurant ist auf allen gängigen Smartphones und Tablets verfügbar
                </ListItem>
                <ListItem>Auf Wunsch kann ein Gerät mit Thermaldrucker bereitgestellt werden</ListItem>
                <ListItem>Kunden können über die Webseite (www.bestelleck.de) oder per App bestellen</ListItem>
            </div>
        </div>
        <div className={styles.whyContainer}>
            <h1>Ihre Vorteile</h1>
            <div className={styles.instructions}>
                <Card className={styles.card}>
                    <CardContent>
                        <Typography variant="h5">Digitalisierung</Typography>
                        <Typography>
                            Sagen Sie Adieu zu Zettelwirtschaft und Telefonchaos! Mit BestellEck geht nie wieder eine
                            Bestellung unter und Missverständnisse bei der Bestellung sind Geschichte.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={styles.card}>
                    <CardContent>
                        <Typography variant="h5">Kontaktlose Zahlung</Typography>
                        <Typography>
                            Mit den Onlinebezahldiensten können Sie Ihren Kunden eine günstige und einfache Möglichkeit
                            bieten, ihre Bestellungen bereits im Voraus zu bezahlen.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={styles.card}>
                    <CardContent>
                        <Typography variant="h5">Keine Lust auf hohe Provision?</Typography>
                        <Typography>
                            Verstehen wir! Die Preise, die etablierte Portale verlangen, sind alles andere als fair.
                            Deshalb bieten wir ein Festpreismodell mit kalkulierbaren Kosten.
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
        <div className={styles.about}>
            <h1>Interesse? </h1>
            <p>
                Dann kontaktieren Sie uns einfach: <br /> <br />
                <span>E-Mail:</span> kontakt@bestelleck.de <br />
                <span>Telefon:</span> +49 159 06101381 <br /> <br />
                Wir freuen uns auf Ihre Anfrage!
            </p>
        </div>
    </div>
);
/*<h3>Unterstütze jetzt deine lokalen Restaurants mit einer Bestellung.</h3>
    <h3>
      Keine Lust auf hohe Provisionen? Wir garantieren dauerhaft faire Preise.
    </h3>
    <h3>
      Bei einer Bestellung im Wert von 30€ bezahlt dein Restaurant eine Gebühr
      von fast 4€. Das finden wir nicht gerechtfertigt.
    </h3>
    <h3>Langer Tag? Gönn dir eine Auszeit.</h3>
    <h3>Abholung oder Lieferung? Bestell bei dir ums Eck!</h3>
     <div className={styles.whyContainer}>
      <h3>Warum BestellEck?</h3>
      <h4>3€</h4>
      <div>
        Von deiner Bestellung gehen bei herkömmlichen Essensplattformen nichts
        ans Restaurant
      </div>
      <div>Ist das Fair? Auf keinen Fall!</div>
      <h4>
        {" "}
        Die Gebühren bei BestellEck: {"<"} 0,5€ pro Bestellung (bei 40
        Bestellungen im Monat)
      </h4>
    </div>
    */
export default Info;
