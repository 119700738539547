import React from 'react';

import { dayOfWeekAsString } from '@bestelleck/shared';
import { convertFramesToTexts, getOpeningHoursForDayOfWeek, RestaurantDetail } from '@bestelleck/utils';
import { useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

import { DialogTitle } from '../../../components/DialogTitle/DialogTitle';
import PaymentOptionsComponent from '../../../components/PaymentOptions/PaymentOptions';
import { getUniqueKey } from '../../../util/utils';

import styles from './InfoDialog.module.scss';

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

type InfoDialogComponentProps = {
    open: boolean;
    restaurant: RestaurantDetail;
    handleClose: () => void;
};

const InfoDialogComponent: React.FC<InfoDialogComponentProps> = ({ open, restaurant, handleClose }) => {
    const hasDelivery = restaurant?.delivery ? true : false;
    const isSmallScreen = useMediaQuery('(max-width:750px)');
    const today = new Date();

    const openingTimes = restaurant.pickup.days.map((day) => {
        let times;
        if (day.dayOfWeek === today.getDay()) {
            const scheduleDay = convertFramesToTexts(
                getOpeningHoursForDayOfWeek(restaurant.pickup.days, restaurant.pickup.exceptions, day.dayOfWeek, today),
            );
            times = scheduleDay.map((text, index) => {
                return <div key={`${day.dayOfWeek}-${index}`}>{text}</div>;
            });
        } else {
            times = convertFramesToTexts(
                getOpeningHoursForDayOfWeek(restaurant.pickup.days, restaurant.pickup.exceptions, day.dayOfWeek, today),
            ).map((frame, index) => <div key={`${day.dayOfWeek}-${index}`}>{frame}</div>);
        }

        return (
            <div key={getUniqueKey()} className={styles.time}>
                {dayOfWeekAsString(day.dayOfWeek)} {times.length > 0 && <div>{times}</div>}
                {times.length === 0 && <div>Geschlossen</div>}
            </div>
        );
    });
    const deliveryTimes = restaurant.delivery?.days.map((day) => {
        let times;
        if (day.dayOfWeek === today.getDay()) {
            const scheduleDay = convertFramesToTexts(
                getOpeningHoursForDayOfWeek(
                    restaurant.delivery.days,
                    restaurant.delivery.exceptions,
                    day.dayOfWeek,
                    today,
                ),
            );
            times = scheduleDay.map((text, index) => {
                return <div key={`${day.dayOfWeek}-${index}`}>{text}</div>;
            });
        } else {
            times = convertFramesToTexts(
                getOpeningHoursForDayOfWeek(
                    restaurant.delivery.days,
                    restaurant.delivery.exceptions,
                    day.dayOfWeek,
                    today,
                ),
            ).map((frame, index) => <div key={`${day.dayOfWeek}-${index}`}>{frame}</div>);
        }

        return (
            <div key={getUniqueKey()} className={styles.time}>
                {dayOfWeekAsString(day.dayOfWeek)} {times.length > 0 && <div>{times}</div>}
                {times.length === 0 && <div>Geschlossen</div>}
            </div>
        );
    });
    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
                fullScreen={isSmallScreen}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Informationen zum Restaurant
                </DialogTitle>
                <DialogContent dividers className={styles.root}>
                    <div className={styles.section}>
                        <div className={styles.header}>Adresse</div>
                        <div className={styles.content}>
                            <div>{restaurant.name}</div>
                            <div>{restaurant.contact.street}</div>
                            <div>
                                {restaurant.contact.postalCode} {restaurant.contact.city}
                            </div>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.header}>Öffnungszeiten</div>
                        <div className={styles.content}>
                            <div>{openingTimes}</div>
                        </div>
                    </div>
                    {hasDelivery && (
                        <div className={styles.section}>
                            <div className={styles.header}>Lieferzeiten</div>
                            <div className={styles.content}>
                                <div>{deliveryTimes}</div>
                            </div>
                        </div>
                    )}
                    <div className={styles.section}>
                        <div className={styles.header}>Bezahlmethoden</div>
                        <div className={styles.content}>
                            <PaymentOptionsComponent
                                paymentOptions={restaurant.payment.paymentOptions}
                                framed={true}
                            ></PaymentOptionsComponent>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.header}>Kontakt</div>
                        <div className={styles.content}>
                            <div>
                                {' '}
                                {restaurant.contact.ownerName} handelt im Namen von {restaurant.name}
                            </div>
                            <div>
                                {' '}
                                {restaurant.contact.postalCode} {restaurant.contact.city}
                            </div>
                            <div></div>
                            <div> Tel: {restaurant.contact.phoneNumber} </div>
                            <div> E-Mail: {restaurant.contact.email} </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default InfoDialogComponent;
