import React from 'react';

import { IoCheckmark } from 'react-icons/io5';

import styles from './ListItem.module.scss';

export const ListItem: React.FC = (props) => {
    return (
        <div className={styles.root}>
            <IoCheckmark /> <div className={styles.children}>{props.children}</div>
        </div>
    );
};
