import { NewsletterSubscription } from '../types/Subscription';
import { baseUrl } from '../util/constants';
import { customFetch } from '../util/utils';

export const getNewsletterInfo = async (id: string): Promise<NewsletterSubscription> => {
    const url = `${baseUrl}/newsletter/subscriptions/${id}`;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', pragma: 'no-cache', 'cache-control': 'no-cache' },
    };
    return customFetch(url, requestOptions);
};

export const unsubscribeNewsletter = async (id: string) => {
    const url = `${baseUrl}/newsletter/subscriptions/${id}`;
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(url, requestOptions);
};
