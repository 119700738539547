import React from 'react';

import { DialogTitle } from '@bestelleck/shared';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

import { FilterComponent } from '../FilterComponents/FilterComponent';

import styles from './FilterDialog.module.scss';

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

interface FilterDialogProps {
    open: boolean;
    handleClose: () => void;
}

const FilterDialog: React.FC<FilterDialogProps> = ({ open, handleClose }) => {
    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullScreen>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Filter
                </DialogTitle>
                <DialogContent dividers className={styles.content}>
                    <FilterComponent />
                    <div className={styles.filler}></div>
                    <Button className={styles.button} variant="contained" onClick={() => handleClose()}>
                        Ergebnisse anzeigen
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default FilterDialog;
