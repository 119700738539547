import React, { Dispatch, useEffect, useState } from 'react';

import { getDescriptionText, isDescriptionsExpandable, prettyTime, seperateDescriptions } from '@bestelleck/shared';
import {
    convertDateToTime,
    RestaurantDetail,
    OrderType,
    convertFramesToTexts,
    getOpeningHoursForDate,
} from '@bestelleck/utils';
import { DriveEta, InfoOutlined, Search, Euro } from '@mui/icons-material';
import { Button, Collapse, IconButton, InputAdornment, OutlinedInput, useMediaQuery } from '@mui/material';
import { IoStar, IoTimeOutline, IoTimerOutline } from 'react-icons/io5';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setSearch } from '../../../redux/app/app.actions';
import { setOrderType } from '../../../redux/orderType/orderType.actions';
import { RootState } from '../../../redux/store';
import { Tag } from '../../../types/Restaurant';
import { baseUrl } from '../../../util/constants';
import { getDeliveryPlaceForLocation } from '../../../util/deliveryPlaces';
import useWindowDimensions from '../../../util/useWindowDimension';
import {
    calculateIsOpen,
    formatNumber,
    formatPrice,
    handleErrors,
    isPreOrderCurrentlyPossible,
} from '../../../util/utils';
import InfoDialogComponent from '../InfoDialog/InfoDialog';

import RatingsDialogComponent from './Ratings/Ratings';
import styles from './RestaurantHeader.module.scss';

export type RestaurantHeaderProps = {
    restaurant: RestaurantDetail;
};

const RestaurantHeaderComponent: React.FC<RestaurantHeaderProps> = ({ restaurant }) => {
    const { width } = useWindowDimensions();
    const isSmallScreen = useMediaQuery('(max-width:750px)');
    const imageWidth = isSmallScreen ? width * 1.5 : width;
    const image = `${baseUrl}/image/${restaurant.imageLink}?width=${imageWidth}`;
    const logo = `${baseUrl}/image/${restaurant.avatarImageLink}`;

    const dispatch: Dispatch<any> = useDispatch();

    const location = useSelector((state: RootState) => state.app.location, shallowEqual);

    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);
    const deliverySelected = orderType === OrderType.Delivery;

    const deliveryAvailable = restaurant.orderTypes.find((value) => value === 'delivery') ? true : false;
    let isDelivery = deliveryAvailable && deliverySelected;

    const currentDate = new Date();
    const currentTime = convertDateToTime(currentDate);

    const isOpen = calculateIsOpen(restaurant, orderType);
    const openingSchedule = isDelivery ? restaurant.delivery : restaurant.pickup;
    const nextOpeningFrame = getOpeningHoursForDate(
        openingSchedule.days,
        openingSchedule.exceptions,
        currentDate,
    )?.find((frame) => frame.from > currentTime);

    const [openInfo, setOpenInfo] = useState(false);
    const [restaurantTags, setTags] = useState<Tag[]>([]);
    const [openRatings, setOpenRatings] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const openInfoDialog = () => {
        setOpenInfo(true);
    };

    const closeInfoDialog = () => {
        setOpenInfo(false);
    };

    const url = `${baseUrl}/tags/restaurants`;
    useEffect(() => {
        fetch(url)
            .then(handleErrors)
            .then((res) => res.json())
            .then((result) => {
                setTags(result);
            });
    }, [url]);

    const days = isDelivery ? restaurant.delivery.days : restaurant.pickup.days;
    const exceptions = isDelivery ? restaurant.delivery.exceptions : restaurant.pickup.exceptions;
    const currentDay = days.find((day) => day.dayOfWeek === new Date().getDay());
    const openingHours = getOpeningHoursForDate(days, exceptions, currentDate);
    const preOrderPossible = isPreOrderCurrentlyPossible(restaurant, isDelivery, isOpen);
    let openTimes;
    if (currentDay) {
        const framesText = convertFramesToTexts(openingHours);
        if (isSmallScreen) {
            openTimes = framesText.map((time, index) => <div key={index}>{time}</div>);
        } else {
            openTimes = framesText.join(', ');
        }
    }

    const tags = restaurant?.tags.map((tag) => restaurantTags.find((rTag) => rTag.id === tag)?.name).join(', ');

    const delivery = isDelivery
        ? getDeliveryPlaceForLocation(restaurant.delivery.places, location.deliveryPlaces)
        : null;

    if (isDelivery && !delivery) {
        isDelivery = false;
        dispatch(setOrderType({ orderType: OrderType.PickUp }));
    }
    const canExpand = isDescriptionsExpandable(restaurant.descriptions);
    let description;
    if (!canExpand) {
        description = restaurant.descriptions.map((desc, index) => {
            return (
                <div key={index} className={styles.line}>
                    {getDescriptionText(desc)}
                </div>
            );
        });
    } else if (canExpand) {
        const [defaultDescriptions, expandedDescriptions] = seperateDescriptions(restaurant.descriptions);
        const classNames = [styles.line, styles.descriptionSmall].join(' ');
        description = (
            <div className={classNames}>
                <div className={styles.inline}>
                    {defaultDescriptions.map((desc, index) => {
                        return (
                            <div key={index} className={styles.descriptionEntry}>
                                {getDescriptionText(desc)}
                            </div>
                        );
                    })}

                    {!expanded && (
                        <div onClick={() => setExpanded(true)} className={styles.more}>
                            Weiterlesen
                        </div>
                    )}
                    <Collapse className={styles.collapse} in={expanded} timeout="auto" unmountOnExit>
                        {expandedDescriptions.map((desc, index) => {
                            return (
                                <div className={styles.descriptionEntry} key={index}>
                                    {getDescriptionText(desc)}
                                </div>
                            );
                        })}
                    </Collapse>
                    {expanded && (
                        <div onClick={() => setExpanded(false)} className={styles.more}>
                            Weniger anzeigen
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <h2 id="header">{restaurant.name}</h2>
                <h3>{tags}</h3>
            </div>
            <img className={styles.image} src={image} alt="Restaurant Header" />
            {!isSmallScreen && (
                <div className={styles.infoContainer}>
                    <img src={logo} className={styles.logo} alt="Restaurant Logo" />
                    <div className={styles.infoText}>
                        {isOpen && (
                            <div className={styles.infoLine}>
                                <IoTimeOutline /> Geöffnet: <div className={styles.times}>{openTimes}</div>
                            </div>
                        )}

                        {!isOpen && (
                            <div className={styles.infoLine}>
                                <IoTimeOutline />
                                {nextOpeningFrame && (
                                    <div>
                                        Öffnet um {prettyTime(nextOpeningFrame.from)} Uhr
                                        {preOrderPossible && (
                                            <>
                                                <span> - </span>
                                                <span className={styles.preOrderSpan}>Vorbestellung möglich</span>
                                            </>
                                        )}
                                    </div>
                                )}
                                {!nextOpeningFrame && <div>Momentan geschlossen</div>}
                            </div>
                        )}

                        <div className={styles.rating}>
                            <IoStar />
                            <span>
                                {restaurant.ratings.average !== 0 ? formatNumber(restaurant.ratings.average) : '-'}/ 5
                            </span>
                            <span
                                className={restaurant.ratings.count > 0 ? styles.countHover : styles.count}
                                onClick={() => {
                                    if (restaurant.ratings.count > 0) {
                                        setOpenRatings(true);
                                    }
                                }}
                            >
                                ({restaurant.ratings.count} Bewertungen)
                            </span>
                        </div>
                        {delivery && (
                            <div className={styles.infoLine}>
                                <Euro></Euro>
                                {`Mindestbestellwert: ${formatPrice(delivery.minimumOrderValue)}`}
                            </div>
                        )}
                        {delivery && (
                            <div className={styles.infoLine}>
                                <DriveEta />
                                {`Lieferung in: 
            ${restaurant.orderTime.min + delivery.duration} - ${restaurant.orderTime.max + +delivery.duration} min`}
                            </div>
                        )}
                        {!isDelivery && (
                            <div className={styles.infoLine}>
                                <IoTimerOutline />
                                {`Abholung in: 
            ${restaurant.orderTime.min} -${restaurant.orderTime.max} min`}
                            </div>
                        )}
                        {description}
                    </div>
                    <div className={styles.buttons}>
                        <OutlinedInput
                            className={styles.search}
                            placeholder="Suchen"
                            onChange={(event) => {
                                dispatch(setSearch(event.target.value));
                            }}
                            color="secondary"
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton size="large">
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Button
                            id="infoButton"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                openInfoDialog();
                            }}
                        >
                            <InfoOutlined /> Info
                        </Button>
                    </div>
                </div>
            )}
            {isSmallScreen && (
                <div className={styles.infoContainer}>
                    <div className={styles.infoTextSmall}>
                        <div className={styles.line} style={{ marginBottom: 0 }}>
                            {isOpen && (
                                <div className={styles.openingTimes}>
                                    <IoTimeOutline /> Geöffnet: <div className={styles.times}>{openTimes}</div>
                                </div>
                            )}
                            {!isOpen && (
                                <div className={styles.openingTimes}>
                                    <IoTimeOutline />
                                    {nextOpeningFrame && (
                                        <div className={styles.openingTimesText}>
                                            Öffnet um {prettyTime(nextOpeningFrame.from)} Uhr
                                            {preOrderPossible && (
                                                <>
                                                    <span> - </span>
                                                    <span className={styles.preOrderSpan}>Vorbestellung möglich</span>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    {!nextOpeningFrame && <div>Momentan geschlossen</div>}
                                </div>
                            )}
                            <Button
                                id="infoButton"
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    openInfoDialog();
                                }}
                            >
                                <InfoOutlined /> Info
                            </Button>
                        </div>
                        <div className={styles.line}>
                            <div className={styles.rating}>
                                <IoStar />
                                <span>{restaurant.ratings.average !== 0 ? restaurant.ratings.average : '-'}/ 5</span>
                                <span
                                    className={restaurant.ratings.count > 0 ? styles.countHover : styles.count}
                                    onClick={() => {
                                        if (restaurant.ratings.count > 0) {
                                            setOpenRatings(true);
                                        }
                                    }}
                                >
                                    ({restaurant.ratings.count} Bewertungen)
                                </span>
                            </div>
                        </div>
                        {isDelivery && delivery && (
                            <div>
                                <div className={styles.line}>
                                    <div>
                                        <Euro></Euro>
                                        {`Mindestbestellwert: ${formatPrice(delivery.minimumOrderValue)}`}
                                    </div>
                                </div>
                                <div className={styles.line}>
                                    <div>
                                        <DriveEta />
                                        {`Lieferung in: 
             ${restaurant.orderTime.min + delivery.duration} - ${restaurant.orderTime.max + +delivery.duration} min`}
                                    </div>
                                </div>
                            </div>
                        )}
                        {!isDelivery && (
                            <div className={styles.line}>
                                <div>
                                    <IoTimerOutline />
                                    {`Abholung in: 
            ${restaurant.orderTime.min} -${restaurant.orderTime.max} min`}
                                </div>
                            </div>
                        )}
                        {description}
                        <div className={styles.line}>
                            <OutlinedInput
                                className={styles.search}
                                onChange={(event) => {
                                    dispatch(setSearch(event.target.value));
                                }}
                                placeholder="Suchen"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton size="large">
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
            {openInfo && (
                <InfoDialogComponent
                    open={openInfo}
                    handleClose={closeInfoDialog}
                    restaurant={restaurant}
                ></InfoDialogComponent>
            )}
            {openRatings && (
                <RatingsDialogComponent
                    open={openRatings}
                    ratingStats={restaurant.ratings}
                    restaurantId={restaurant.id}
                    handleClose={() => {
                        setOpenRatings(false);
                    }}
                ></RatingsDialogComponent>
            )}
        </div>
    );
};

export default RestaurantHeaderComponent;
