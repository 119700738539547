import React, { useEffect } from 'react';

import { prettyTime } from '@bestelleck/shared';
import {
    RestaurantDetail,
    OrderType,
    getOpeningHoursForDate,
    getCityDisplay,
    ScheduleDayFrame,
} from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FormControl, InputLabel, Select, TextField, useMediaQuery } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { useFormContext } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import isEmail from 'validator/lib/isEmail';

import { RootState } from '../../../redux/store';
import { getUniqueKey } from '../../../util/utils';

import { DeliverySelectComponent } from './DeliverySelect/DeliverySelect';
import styles from './Form.module.scss';

type CheckoutFormProps = {
    restaurant: RestaurantDetail | undefined;
    isPreOrder: boolean;
    setOpenAdressDialog: (open: boolean) => void;
};

export const CheckoutForm: React.FC<CheckoutFormProps> = ({ restaurant, isPreOrder, setOpenAdressDialog }) => {
    const {
        register,
        watch,
        reset,
        formState: { errors },
    } = useFormContext();
    const { trackEvent } = useMatomo();
    const watchAllFields = watch();
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);
    const contact = useSelector((state: RootState) => state.app.contact, shallowEqual);
    const locationInfo = useSelector((state: RootState) => state.app.location, shallowEqual);

    useEffect(() => {
        const houseNumber = locationInfo.manual
            ? locationInfo.manual.houseNumber
            : locationInfo.place.address?.house_number;

        reset({
            houseNumber,
        });
    }, [locationInfo, reset]);

    const isDelivery = orderType === OrderType.Delivery;

    const hasDelivery = restaurant?.delivery ? true : false;

    const trackFormClick = (name: string) => {
        trackEvent({ action: `Click ${name}`, category: 'Checkout' });
    };
    const currentDate = new Date();

    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
    const add = 5 - (currentTime % 5);
    const addTime = orderType === OrderType.Delivery ? 60 : 30;
    let startingTime = currentTime + add + addTime;
    if (restaurant) {
        const openingSchedule = isDelivery ? restaurant.delivery : restaurant.pickup;
        const nextOpeningFrame = getOpeningHoursForDate(
            openingSchedule.days,
            openingSchedule.exceptions,
            currentDate,
        )?.find((frame) => frame.from > currentTime);
        const currentOpeningFrame = getOpeningHoursForDate(
            openingSchedule.days,
            openingSchedule.exceptions,
            currentDate,
        )?.find((frame) => frame.from < currentTime && frame.to > currentTime);

        if (isPreOrder && nextOpeningFrame) {
            startingTime = nextOpeningFrame?.from + addTime;
        } else if (
            !isPreOrder &&
            currentOpeningFrame &&
            currentOpeningFrame.to < currentTime + addTime &&
            nextOpeningFrame
        ) {
            startingTime = nextOpeningFrame?.from + addTime;
        }
    }

    let openingScheduleToday: ScheduleDayFrame[] = [];

    if (orderType === OrderType.Delivery) {
        openingScheduleToday = getOpeningHoursForDate(
            restaurant?.delivery.days || [],
            restaurant?.delivery.exceptions || [],
            new Date(),
        );
    } else if (orderType === OrderType.PickUp) {
        openingScheduleToday = getOpeningHoursForDate(
            restaurant?.pickup.days || [],
            restaurant?.pickup.exceptions || [],
            new Date(),
        );
    }

    const cityDisplay = getCityDisplay(locationInfo.place);

    const displayCity = locationInfo.manual
        ? `${locationInfo.manual.deliveryPlace.postalCode} ${locationInfo.manual.deliveryPlace.city}`
        : `${locationInfo.place.address?.postcode ? locationInfo.place.address.postcode : ''} ${
              cityDisplay ? cityDisplay : ''
          }`;

    const orderTimeOptions: number[] =
        openingScheduleToday
            .map((frame) => {
                const availableTimes = [];
                let time = frame.from < startingTime ? startingTime : frame.from;
                while (time < frame.to) {
                    availableTimes.push(time);
                    time += 5;
                }

                return availableTimes;
            })
            .flatMap((a) => a) || [];

    const timeOptions = orderTimeOptions.map((option) => (
        <option key={getUniqueKey()} value={option}>
            {prettyTime(option)}
        </option>
    ));

    return (
        <>
            <h1>
                Fast fertig! Deine {isPreOrder ? 'Vorbestellung' : 'Bestellung'} zur{' '}
                {isDelivery ? 'Lieferung' : 'Abholung'} bei {restaurant?.name}
            </h1>
            {hasDelivery && <DeliverySelectComponent restaurant={restaurant} />}
            <h3> Wie können wir dich erreichen?</h3>
            <div className={styles.row}>
                <TextField
                    {...register('name', { required: 'Bitte gebe deinen Namen ein' })}
                    error={errors.name ? true : false}
                    helperText={errors?.name ? errors.name.message : null}
                    required
                    defaultValue={contact.name}
                    onClick={() => {
                        trackFormClick('name');
                    }}
                    onFocus={() => {
                        trackFormClick('name');
                    }}
                    id="name"
                    name="name"
                    label="Vor- und Nachname"
                    variant="outlined"
                    className={styles.half}
                    color="secondary"
                />
                <TextField
                    {...register('email', {
                        required: 'Bitte gebe eine valide E-Mail Adresse ein',
                        validate: (value) => isEmail(value.trim()),
                    })}
                    error={errors.email ? true : false}
                    helperText={errors?.email ? errors.email.message : null}
                    required
                    defaultValue={contact.email}
                    onClick={() => {
                        trackFormClick('email');
                    }}
                    onFocus={() => {
                        trackFormClick('email');
                    }}
                    id="email"
                    name="email"
                    label="E-Mail"
                    variant="outlined"
                    className={styles.half}
                    color="secondary"
                />
                <TextField
                    {...register('phone', {
                        required: 'Bitte gebe deine Telefonnummer ein',
                        validate: (value) => {
                            return isValidPhoneNumber(value.trim(), 'DE');
                        },
                    })}
                    error={errors.phone ? true : false}
                    helperText={errors?.phone ? errors.phone.message : null}
                    required
                    defaultValue={contact.phone}
                    type="tel"
                    onClick={() => {
                        trackFormClick('phone');
                    }}
                    onFocus={() => {
                        trackFormClick('phone');
                    }}
                    id="phone"
                    name="phone"
                    label="Telefonnummer"
                    variant="outlined"
                    className={styles.half}
                    color="secondary"
                />
            </div>
            {isDelivery && <h3> Wohin soll die Bestellung geliefert werden?</h3>}
            {isDelivery && (
                <div className={styles.row}>
                    <TextField
                        {...register('address', {
                            minLength: 1,
                            maxLength: 100,
                            required: 'Bitte gebe deine Adresse ein',
                        })}
                        error={errors.address ? true : false}
                        helperText={errors?.address ? errors.address.message : null}
                        required
                        InputProps={{
                            readOnly: true,
                        }}
                        value={
                            locationInfo.manual?.address ||
                            locationInfo.place.address?.road ||
                            locationInfo.place.address?.city_block ||
                            ''
                        }
                        onClick={() => {
                            trackFormClick('address');
                            setOpenAdressDialog(true);
                        }}
                        id="address"
                        name="address"
                        label="Straße"
                        variant="outlined"
                        className={styles.half}
                        color="secondary"
                    />
                    <TextField
                        {...register('houseNumber', {
                            minLength: 1,
                            maxLength: 20,
                            required: 'Bitte gebe deine Hausnummer ein',
                        })}
                        error={errors.houseNumber ? true : false}
                        helperText={errors?.houseNumber ? errors.houseNumber.message : null}
                        required
                        defaultValue={
                            locationInfo.manual?.houseNumber || locationInfo.place.address?.house_number || ''
                        }
                        onClick={() => {
                            trackFormClick('houseNumber');
                        }}
                        id="houseNumber"
                        name="houseNumber"
                        label="Hausnummer"
                        variant="outlined"
                        className={styles.quarter}
                        color="secondary"
                    />
                    <FormControl variant="outlined" className={[styles.half, styles.select].join(' ')}>
                        <TextField
                            required
                            InputProps={{ readOnly: true }}
                            value={displayCity.trim()}
                            onClick={() => {
                                setOpenAdressDialog(true);
                                trackFormClick('city');
                            }}
                            label="Stadt"
                            variant="outlined"
                            className={styles.select}
                            color="secondary"
                        />
                    </FormControl>
                </div>
            )}
            <h3> Wann möchtest Du Deine Bestellung {isDelivery ? 'erhalten' : 'abholen'}?</h3>
            <div className={styles.row}>
                <FormControl variant="outlined" className={[styles.half, styles.select].join(' ')}>
                    <InputLabel>{isDelivery ? 'Lieferzeit' : 'Abholzeit'}</InputLabel>
                    {isPreOrder && orderTimeOptions.length > 0 && (
                        <Select
                            native
                            {...register('preOrderTime')}
                            required
                            defaultValue={orderTimeOptions[0]}
                            value={watchAllFields.preOrderTime}
                            onClick={() => {
                                trackFormClick('preOrderTime');
                            }}
                            onFocus={() => {
                                trackFormClick('preOrderTime');
                            }}
                            label="Abholzeit"
                            variant="outlined"
                            className={styles.half}
                            color="secondary"
                        >
                            {timeOptions}
                        </Select>
                    )}
                    {!isPreOrder && (
                        <Select
                            native
                            {...register('deliveryTime')}
                            required
                            defaultValue={undefined}
                            value={watchAllFields.deliveryTime}
                            onClick={() => {
                                trackFormClick('deliveryTime');
                            }}
                            onFocus={() => {
                                trackFormClick('deliveryTime');
                            }}
                            label="Abholzeit"
                            variant="outlined"
                            className={styles.half}
                            color="secondary"
                        >
                            <option value={undefined}>Schnellstmöglich</option>
                            {timeOptions}
                        </Select>
                    )}
                </FormControl>

                <TextField
                    {...register('notes')}
                    multiline
                    minRows={isSmallScreen ? 1 : 6}
                    onClick={() => {
                        trackFormClick('note');
                    }}
                    onFocus={() => {
                        trackFormClick('note');
                    }}
                    id="outlined-basic"
                    label="Anmerkungen"
                    key="Anmerkungen"
                    variant="outlined"
                    className={styles.half}
                    color="secondary"
                />
            </div>
        </>
    );
};
