import React from 'react';

import { Rating as RatingComponent } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Rating } from '../../../../../types/Restaurant';

import styles from './RatingCard.module.scss';

export type RatingCardProps = {
    rating: Rating;
};

const RatingCardComponent: React.FC<RatingCardProps> = ({ rating }) => {
    const { t } = useTranslation();
    const createdDay = new Date(rating.createDate).toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const ratings = rating.values.map((value) => {
        const category = t('ratingCategory.' + value.category);
        return (
            <div key={`${rating.id}-${value.category}`} className={styles.category}>
                <div>{category}</div> <RatingComponent readOnly name="rating-Readonly" value={value.value} />
            </div>
        );
    });
    return (
        <div className={styles.root}>
            <div className={styles.name}>{rating.username}</div>
            <div className={styles.date}>{createdDay}</div>
            {ratings}
            <div className={styles.description}> {rating.description}</div>
        </div>
    );
};

export default RatingCardComponent;
