import React, { Dispatch, useState } from 'react';

import { Button, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import NumberControl from '../../../components/NumberControl/NumberControl';
import { updateItem, removeItem } from '../../../redux/cart/actionCreators';
import { CartItem } from '../../../redux/cart/types';
import { calculatePriceForItem, formatPrice } from '../../../util/utils';

import styles from './CartItem.module.scss';

interface CartItemProps {
    item: CartItem;
    isDelivery: boolean;
}

const CartItemComponent: React.FC<CartItemProps> = ({ item, isDelivery }) => {
    const dispatch: Dispatch<any> = useDispatch();

    const { id } = useParams<{ id: string }>();

    const [edit, setEdit] = useState(false);
    const [note, setNote] = useState(item.note);

    const handleChange = (event: any) => {
        setNote(event.target.value);
    };

    const updateItemCount = (item: CartItem, count: number) => {
        const amount = item.amount + count;
        if (amount > 0) {
            const updatedItem: CartItem = {
                ...item,
                amount,
            };
            return dispatch(updateItem(updatedItem, id));
        }
        dispatch(removeItem(item, id));
    };

    const updateNote = (item: CartItem, note: string) => {
        const updatedItem: CartItem = {
            ...item,
            note,
        };
        return dispatch(updateItem(updatedItem, id));
    };

    const optionsDisplay = item.options.map((option) => option?.selectedValue.name);

    const extraDisplay = item.extraGroups.map((extraGroup) => {
        const extras = extraGroup.extras.map((extra) => extra.name).join(', ');
        if (extras !== '') {
            return (
                <div className={styles.extras} key={`${extraGroup.name}`}>
                    <span className={styles.label}>{extraGroup.name}: </span>
                    {extras}
                </div>
            );
        }
        return <div key={extraGroup.name}></div>;
    });

    return (
        <div className={styles.root}>
            <div className={styles.number}>
                <span>{item.amount}x</span>
            </div>
            <div className={styles.text}>
                <span>{item.name}</span>
            </div>
            <div className={styles.price}>{formatPrice(calculatePriceForItem(item, isDelivery))}</div>
            <div className={styles.details}>
                <div className={styles.options}>
                    {optionsDisplay.length > 0 && (
                        <div>
                            <span className={styles.bold}>Auswahl: </span> {optionsDisplay.join(', ')}
                        </div>
                    )}
                </div>
                <div className={styles.extras}>{extraDisplay.length > 0 && <div>{extraDisplay}</div>}</div>
            </div>
            <div className={styles.anmerkung}>
                <div className={styles.extras}>
                    {item.note !== '' && !edit && (
                        <div>
                            <span className={styles.bold}>Anmerkung: </span> {item.note}
                        </div>
                    )}
                </div>
                {!edit && (
                    <div
                        className={styles.addNote}
                        onClick={(event) => {
                            event.stopPropagation();
                            setEdit(true);
                        }}
                    >
                        Anmerkung {item.note === '' ? 'hinzufügen' : 'bearbeiten'}
                    </div>
                )}
            </div>
            <div className={styles.control}>
                <NumberControl item={item} itemCount={item.amount} updateItemCount={updateItemCount} />
            </div>
            {edit && (
                <div className={styles.noteContainer}>
                    <TextField
                        size="small"
                        className={styles.note}
                        value={note}
                        onChange={handleChange}
                        label="Anmerkung"
                        variant="outlined"
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        onBlur={() => {
                            updateNote(item, note);
                            setEdit(false);
                        }}
                        color="secondary"
                    />
                    <div className={styles.buttons}>
                        <Button
                            onClick={(event) => {
                                event.stopPropagation();
                                setEdit(false);
                            }}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            onClick={(event) => {
                                event.stopPropagation();
                                updateNote(item, note);
                                setEdit(false);
                            }}
                        >
                            Speichern
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CartItemComponent;
