import { PaymentOption } from '@bestelleck/utils';

import { RestaurantTags } from '../../types/Restaurant';

import {
    AppAction,
    AppState,
    APP_ACTION_TYPES,
    ContactAction,
    FilterAction,
    OrderAction,
    PaymentAction,
    PostalCodeAction,
    RateAction,
    RestaurantAction,
    RouteAction,
    SearchAction,
    TagAction,
    SelectedPaymentAction,
} from './app.types';

const initialState: AppState = {
    selectedTags: [RestaurantTags.ALL],
    search: '',
    route: {
        previous: '',
        current: '',
    },
    selectedPaymentOption: PaymentOption.Cash,
    location: {
        deliveryPlaces: [],
        place: {
            boundingbox: [],
            class: '',
            display_name: '',
            icon: '',
            importance: 0,
            lat: '',
            licence: '',
            lon: '',
            osm_id: 0,
            osm_type: '',
            place_id: 0,
            type: '',
            address: {
                country: '',
                country_code: '',
                county: '',
                house_number: '',
                postcode: '',
                state: '',
                suburb: '',
                city: '',
                neighbourhood: '',
                road: '',
                town: '',
                village: '',
            },
        },
    },
    filters: {
        rating: 'none',
        payment: 'none',
        deliveryCost: -1,
        minimumOrderValue: -1,
    },
    selectedRestaurant: undefined,
    contact: {
        name: undefined,
        email: undefined,
        phone: undefined,
        address: undefined,
        city: undefined,
    },
    order: null,
    version: 0,
    cartVersion: 1,
    hasRated: false,
    paymentOptions: {
        google: false,
        apple: false,
        link: false,
    },
};

const appReducer = (state: AppState = initialState, action: AppAction): AppState => {
    switch (action.type) {
        case APP_ACTION_TYPES.SET_ROUTE:
            return {
                ...state,
                route: {
                    previous: state.route?.current || '',
                    current: (action as RouteAction).route,
                },
            };
        case APP_ACTION_TYPES.SET_POSTAL_CODE:
            action = action as PostalCodeAction;
            return {
                ...state,
                location: {
                    place: action.place,
                    deliveryPlaces: action.deliveryPlaces,
                    manual: action.manual,
                },
            };

        case APP_ACTION_TYPES.SET_FILTER:
            action = action as FilterAction;
            const filters = { ...(state.filters as any) };
            filters[action.attribute] = action.value;
            return {
                ...state,
                filters,
            };

        case APP_ACTION_TYPES.SET_ORDER:
            action = action as OrderAction;
            return {
                ...state,
                order: action.order,
            };

        case APP_ACTION_TYPES.SET_SEARCH:
            action = action as SearchAction;
            return {
                ...state,
                search: action.query,
            };

        case APP_ACTION_TYPES.ADD_TAG:
            const tag = (action as TagAction).tag;
            if (tag === RestaurantTags.ALL) {
                return {
                    ...state,
                    selectedTags: [tag],
                };
            }
            return {
                ...state,
                selectedTags: [tag],
            };
        case APP_ACTION_TYPES.REMOVE_TAG:
            const selectedTags = state.selectedTags.filter((tag) => tag !== (action as TagAction).tag);
            if (selectedTags.length === 0) {
                selectedTags.push(RestaurantTags.ALL);
            }
            return {
                ...state,
                selectedTags,
            };
        case APP_ACTION_TYPES.SET_CONTACT:
            action = action as ContactAction;
            return {
                ...state,
                contact: action.contact,
            };
        case APP_ACTION_TYPES.SET_RATED:
            action = action as RateAction;
            return {
                ...state,
                hasRated: action.rated,
            };
        case APP_ACTION_TYPES.SET_SELECTED_RESTAURANT:
            action = action as RestaurantAction;
            return {
                ...state,
                selectedRestaurant: action.restaurant,
            };
        case APP_ACTION_TYPES.SET_PAYMENT_METHODS:
            action = action as PaymentAction;
            return {
                ...state,
                paymentOptions: action.paymentOptions,
            };
        case APP_ACTION_TYPES.SET_SELECTED_PAYMENT_METHOD:
            action = action as SelectedPaymentAction;
            return {
                ...state,
                selectedPaymentOption: action.paymentOption,
            };
    }
    return state;
};

export default appReducer;
