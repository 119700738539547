import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import { captureException } from '@sentry/react';

import { getNewsletterInfo, unsubscribeNewsletter } from '../../services/newletter.service';
import { NewsletterSubscription } from '../../types/Subscription';
import { useQuery } from '../../util/utils';

import styles from './Unsubscribe.module.scss';

export const UnsubscribeScreen: React.FC = () => {
    const query = useQuery();

    const id = query.get('id');

    const [subscription, setSupscription] = useState<NewsletterSubscription>();
    const [success, setSuccess] = useState(false);

    const unsubscribeFromNewsletter = () => {
        if (id) {
            unsubscribeNewsletter(id)
                .then(() => {
                    setSuccess(true);
                })
                .catch((error) => {
                    captureException(error);
                });
        }
    };

    useEffect(() => {
        if (id) {
            getNewsletterInfo(id)
                .then((response) => {
                    setSupscription(response);
                })
                .catch((error) => {
                    captureException(error);
                });
        }
    }, [id]);

    return (
        <div>
            <h1>Vom Newsletter abmelden</h1>
            {success && <div>Erfolgreich vom Newsletter abgemeldet!</div>}
            {!success && subscription && (
                <div>
                    Klicke auf {'"Abmelden"'} um in Zukunft von uns keine Emails mehr auf folgender E-Mail Adresse zu
                    erhalten:
                    <div className={styles.email}>{subscription?.email}</div>
                    <Button
                        variant="contained"
                        color="primary"
                        className={styles.button}
                        onClick={() => unsubscribeFromNewsletter()}
                    >
                        Abmelden
                    </Button>
                </div>
            )}
            {!success && !subscription && <div>Uns kein aktives Newsletterabonnement bekannt</div>}
        </div>
    );
};
