import React from 'react';

import { ArrowBackIos } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import Drawer from './Drawer/Drawer';
import styles from './Header.module.scss';

export type HeaderProps = {
    onBack: () => void;
    entries: { link: string; name: string }[];
    excludedLocations?: string[];
    logout?: () => void;
};

const Header: React.FC<HeaderProps> = ({ onBack, entries, excludedLocations = [], logout, children }) => {
    const { pathname } = useLocation();
    const isRootLocation = pathname === '/';
    const isExcludedLocation = excludedLocations.includes(pathname);
    const isSmallScreen = useMediaQuery('(max-width:800px)');

    return (
        <AppBar position="static">
            <Toolbar className={styles.Toolbar}>
                {!isRootLocation && !isExcludedLocation && (
                    <IconButton
                        edge="start"
                        className={styles.menuButton}
                        color="inherit"
                        onClick={() => onBack()}
                        aria-label="menu"
                        size="large"
                    >
                        <ArrowBackIos />
                    </IconButton>
                )}
                {!isSmallScreen && (
                    <div className={styles.logo}>
                        <Link to="/" className={styles.title}>
                            <p className={styles.titleText}>BESTELL</p>
                            <p className={styles.titleText + ' ' + styles.grey}>ECK</p>
                            <div className={styles.logoImg}></div>
                        </Link>
                    </div>
                )}

                {isSmallScreen && (
                    <div className={styles.logo}>
                        <Link to="/" className={styles.title}>
                            <div className={styles.logoImg}></div>
                        </Link>
                    </div>
                )}
                <div>{children}</div>

                <div className={styles.actions}>
                    {entries.map((entry) => (
                        <div key={entry.name}>
                            <Link to={entry.link} className={styles.title}>
                                {entry.name}
                            </Link>
                        </div>
                    ))}
                    <div className={styles.menuButton}>
                        <Drawer entries={entries}></Drawer>
                    </div>
                    {logout && (
                        <div onClick={logout} className={styles.logout}>
                            Ausloggen
                        </div>
                    )}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
