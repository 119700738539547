import React from 'react';

import { Coupon, CouponType } from '@bestelleck/utils';

interface CouponLabelComponentProps {
    coupon: Pick<Coupon, 'id' | 'minimumOrderValue' | 'modifier'>;
    isDelivery: boolean;
}

const CouponLabelComponent: React.FC<CouponLabelComponentProps> = ({ coupon, isDelivery }) => {
    const type = coupon.modifier.type === CouponType.Fixed ? '€' : '%';
    const deliveryType = isDelivery ? 'Lieferung' : 'Abholung';
    return (
        <div>
            {coupon.modifier.value}
            {type} bei {deliveryType}
        </div>
    );
};

export default CouponLabelComponent;
