import { Restaurant } from '@bestelleck/utils';

import { RestaurantAction } from './restaurant.type';

export enum RESTAURANT_ACTION_TYPES {
    GET_RESTAURANTS = 'GET_RESTAURANTS',
    UPDATE_RESTAURANTS = 'UPDATE_RESTAURANTS',
    GET_RESTAURANTS_PENDING = 'GET_RESTAURANTS_PENDING',
    GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS',
    GET_RESTAURANTS_ERROR = 'GET_RESTAURANTS_ERROR',
}

export function getRestaurants(): RestaurantAction {
    const action: RestaurantAction = {
        type: RESTAURANT_ACTION_TYPES.GET_RESTAURANTS,
    };
    return action;
}

export function updateRestaurant(restaurants: Restaurant[]): RestaurantAction {
    const action: RestaurantAction = {
        type: RESTAURANT_ACTION_TYPES.UPDATE_RESTAURANTS,
        restaurants,
    };
    return action;
}

export function getRestaurantsPending(): RestaurantAction {
    const action: RestaurantAction = {
        type: RESTAURANT_ACTION_TYPES.GET_RESTAURANTS_PENDING,
    };
    return action;
}

export function getRestaurantsError(error: Error): RestaurantAction {
    const action: RestaurantAction = {
        type: RESTAURANT_ACTION_TYPES.GET_RESTAURANTS_ERROR,
        error,
    };
    return action;
}
export function getRestaurantsSuccess(restaurants: Restaurant[]): RestaurantAction {
    const action: RestaurantAction = {
        type: RESTAURANT_ACTION_TYPES.GET_RESTAURANTS_SUCCESS,
        restaurants,
    };
    return action;
}
