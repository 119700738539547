import React, { Dispatch } from 'react';

import { Button } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { addTag, removeTag } from '../../../../redux/app/app.actions';
import { RootState } from '../../../../redux/store';
import { Tag } from '../../../../types/Restaurant';

import styles from './Token.module.scss';

export interface ITokenComponent {
    tag: Tag;
}

const TokenComponent: React.FC<ITokenComponent> = ({ tag }) => {
    const dispatch: Dispatch<any> = useDispatch();
    const selectedTags: string[] = useSelector((state: RootState) => state.app.selectedTags, shallowEqual);
    const isSelected = selectedTags.find((token) => token === tag.id);

    const updateToken = (tag: string) => {
        if (isSelected) {
            dispatch(removeTag(tag));
        } else {
            dispatch(addTag(tag));
        }
    };
    return (
        <Button
            className={isSelected ? styles.selected : styles.root}
            variant={isSelected ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => updateToken(tag.id)}
        >
            {tag.name}
        </Button>
    );
};

export default TokenComponent;
