import React, { useState } from 'react';

import { CustomError } from '@bestelleck/shared';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { SentimentDissatisfied, SentimentNeutral, SentimentSatisfiedAlt } from '@mui/icons-material';
import { AlertTitle, Button, Snackbar, TextField, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { captureException } from '@sentry/react';
import { useDispatch } from 'react-redux';

import { Alert } from '../../../components/Alert/Alert';
import { DialogTitle } from '../../../components/DialogTitle/DialogTitle';
import { setRated } from '../../../redux/app/app.actions';
import { baseUrl } from '../../../util/constants';

import styles from './RateDialog.module.scss';

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

type RateDialogProps = {
    open: boolean;
    orderId: string;
    handleClose: (rating: number, successful: boolean) => void;
    rating: number;
    setLoading: (isLoading: boolean) => void;
};

const RateDialogComponent: React.FC<RateDialogProps> = ({
    open,
    handleClose,
    setLoading,
    rating: initalRating,
    orderId,
}) => {
    const isSmallScreen = useMediaQuery('(max-width:750px)');
    const dispatch = useDispatch();
    const [rating, setRating] = useState(initalRating);
    const [feedback, setFeedback] = useState('');
    const [ratingError, setRatingError] = useState(false);
    const [done, setDone] = useState(false);
    const { trackEvent } = useMatomo();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedback(event.target.value);
    };

    const postFeedback = () => {
        if (rating === 0) {
            setRatingError(true);
            return;
        }
        const url = `${baseUrl}/feedback`;
        const payload = {
            text: `${orderId}-${rating}-${feedback}`,
        };
        trackEvent({ action: 'Send feedback', category: 'Completed' });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        };

        fetch(url, requestOptions)
            .then((response) => {
                if (response.status === 204) {
                    return response;
                }
                if (!response.ok) {
                    throw new CustomError(response.statusText, response.status);
                }
                return response;
            })
            .then(() => {
                setLoading(false);
                setDone(true);
                dispatch(setRated());
                handleClose(rating, true);
            })
            .catch((error: CustomError) => {
                captureException(error);
                setLoading(false);
                handleClose(rating, false);
            });
    };

    return (
        <div>
            <Dialog
                onClose={() => {
                    handleClose(rating, false);
                }}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
                fullScreen={isSmallScreen}
            >
                <DialogTitle id="customized-dialog-title" onClose={() => handleClose(rating, false)}>
                    Zufrieden? Bitte bewerte uns!
                </DialogTitle>
                <DialogContent dividers className={styles.root}>
                    <div className={styles.section}>
                        <h3>Wie zufrieden warst mit dem Bestellprozess?</h3>
                        <div className={styles.rating}>
                            <SentimentDissatisfied
                                onClick={() => setRating(1)}
                                className={rating === 1 ? styles.bad : styles.normal}
                            />
                            <SentimentNeutral
                                onClick={() => setRating(2)}
                                className={rating === 2 ? styles.neutral : styles.normal}
                            />
                            <SentimentSatisfiedAlt
                                onClick={() => setRating(3)}
                                className={rating === 3 ? styles.good : styles.normal}
                            />
                        </div>
                        {ratingError && <div className={styles.error}> Bitte bewerte uns</div>}
                    </div>
                    <div className={styles.section}>
                        <h3>Was können wir verbessern? (optional)</h3>
                        <TextField
                            id="standard-multiline-flexible"
                            multiline
                            minRows={4}
                            value={feedback}
                            onChange={handleChange}
                            variant="outlined"
                            className={styles.textField}
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        className={styles.button}
                        onClick={() => {
                            postFeedback();
                        }}
                    >
                        Feedback abschicken
                    </Button>
                </DialogContent>
            </Dialog>
            <Snackbar open={done} autoHideDuration={2000} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                <Alert severity="success">
                    <AlertTitle>Fertig!</AlertTitle>
                    Danke für deine Bewertung
                </Alert>
            </Snackbar>
        </div>
    );
};

export default RateDialogComponent;
