import React from 'react';

import styles from './Impressum.module.scss';

const Impressum: React.FC = () => (
    <div className={styles.Impressum}>
        <div>
            <h2>Impressum</h2>
            <h3>Angaben gemäß § 5 TMG</h3>
            <b>BestellEck UG (haftungsbeschränkt)</b>
            <br />
            Mülhauser Straße 27
            <br />
            68229 Mannheim
            <br />
            <br />
            Handelsregister: HRB 740324
            <br />
            Registergericht: Mannheim
            <br />
            <br />
            <b>Vertreten durch:</b>
            <br />
            Slavisa Markovic
            <br />
            <br />
            <b>Kontakt</b>
            <br />
            Telefon: +49 159 06101381
            <br />
            E-Mail: kontakt@bestelleck.de
            <br />
            <br />
            <b>Umsatzsteuer-ID</b>
            <br />
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            <br />
            DE344043839
            <br />
            <br />
            <b>EU-Streitschlichtung</b>
            <br />
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
            https://ec.europa.eu/consumers/odr.
            <br />
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
            <br />
            <br />
            <b>Verbr/aucher­streit­beilegung/Universal­schlichtungs­stelle</b>
            <br />
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbr/aucherschlichtungsstelle teilzunehmen.
            <br />
            <br />
            <b>Haftung für Inhalte</b>
            <br />
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
            Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
            übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
            eine rechtswidrige Tätigkeit hinweisen.
            <br />
            <br />
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
            konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
            <br />
            <br />
            <b>Haftung für Links</b>
            <br />
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
            Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
            wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
            Zeitpunkt der Verlinkung nicht erkennbar.
            <br />
            <br />
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend entfernen.
            <br />
            <br />
            <b>Urheberrecht</b>
            <br />
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
            Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbr/eitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebr/auch gestattet.
            <br />
            <br />
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            <br />
            <br />
            Quelle:{' '}
            <a href="https://www.e-recht24.de/impressum-generator.html">
                https://www.e-recht24.de/impressum-generator.html
            </a>
        </div>
    </div>
);

export default Impressum;
