import React, { Dispatch } from 'react';

import { OrderType } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Divider, useMediaQuery } from '@mui/material';
import { FiShoppingBag } from 'react-icons/fi';
import { IoCarOutline } from 'react-icons/io5';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setOrderType } from '../../../redux/orderType/orderType.actions';
import { RootState } from '../../../redux/store';

import styles from './Filter.module.scss';
import { FilterComponent } from './FilterComponents/FilterComponent';

const Filter: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);
    const { trackEvent } = useMatomo();

    const isDelivery = orderType === OrderType.Delivery;
    const isSmallScreen = useMediaQuery('(max-width:669px)');

    const updateDelivery = (delivery: boolean) => {
        dispatch(setOrderType({ orderType: delivery ? OrderType.Delivery : OrderType.PickUp }));
        trackEvent({
            action: 'Update delivery',
            category: 'Filter',
            name: `${delivery}`,
        });
    };

    const classesLeftButtonFooter = [styles.left, !isDelivery ? styles.containerSelected : styles.container].join(' ');

    return (
        <div className={styles.Filter}>
            {isSmallScreen && (
                <div>
                    <div className={styles.deliverySelect}>
                        <div className={classesLeftButtonFooter}>
                            <Button
                                color={!isDelivery ? 'primary' : 'inherit'}
                                className={styles.button}
                                startIcon={<FiShoppingBag />}
                                onClick={() => {
                                    updateDelivery(false);
                                }}
                            >
                                Abholung
                            </Button>
                        </div>
                        <div className={isDelivery ? styles.containerSelected : styles.container}>
                            <Button
                                color={isDelivery ? 'primary' : 'inherit'}
                                className={styles.button}
                                startIcon={<IoCarOutline style={{ fontSize: 24 }} />}
                                onClick={() => {
                                    updateDelivery(true);
                                }}
                            >
                                Lieferung
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {!isSmallScreen && (
                <div>
                    <div className={styles.buttons}>
                        <Button
                            variant={isDelivery ? 'outlined' : 'contained'}
                            startIcon={<FiShoppingBag />}
                            color="primary"
                            onClick={() => {
                                updateDelivery(false);
                            }}
                        >
                            Abholung
                        </Button>
                        <Button
                            variant={isDelivery ? 'contained' : 'outlined'}
                            startIcon={<IoCarOutline style={{ fontSize: 24 }} />}
                            color="primary"
                            onClick={() => {
                                updateDelivery(true);
                            }}
                        >
                            Lieferung
                        </Button>
                    </div>
                </div>
            )}

            {!isSmallScreen && (
                <div>
                    <Divider />
                    <FilterComponent />
                </div>
            )}
        </div>
    );
};

export default Filter;
