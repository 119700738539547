import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';

import { handleErrorsJson } from '@bestelleck/shared';
import { RestaurantDetail, filterGeoResults, getPlaceDisplay, removeDuplicatesByName, Place } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { CircularProgress, debounce, InputAdornment, TextField, useAutocomplete } from '@mui/material';
import { captureException } from '@sentry/react';
import { MdOutlinePlace } from 'react-icons/md';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { setPostalCode } from '../../redux/app/app.actions';
import { RootState } from '../../redux/store';
import { reverseGeoCode } from '../../services/geo.service';
import { Location } from '../../types/Geo';
import { geoEndpoint } from '../../util/constants';
import { getDeliveryPlaceForLocation } from '../../util/deliveryPlaces';

import styles from './Address.module.scss';

type AddressComponentProps = {
    onSelect?: () => void;
    restaurant?: RestaurantDetail;
    showHelp: boolean;
    increaseTries?: () => void;
};

export const AddressComponent: React.FC<AddressComponentProps> = ({
    onSelect,
    restaurant,
    increaseTries,
    showHelp,
}) => {
    const dispatch: Dispatch<any> = useDispatch();
    const history = useHistory();
    const { trackEvent } = useMatomo();
    const location = useLocation();

    const locationInfo = useSelector((state: RootState) => state.app.location, shallowEqual);

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [geoResults, setGeoResults] = useState<Place[]>([]);
    const [geoSearch, setGeoSearch] = useState('');

    const onInputChange = useCallback(
        (_event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
            trackEvent({
                action: 'Search address',
                category: 'Home',
                name: newInputValue,
            });
            setGeoSearch(newInputValue);
        },
        [trackEvent],
    );
    const debouncedChangeHandler = useMemo(() => debounce(onInputChange, 500), [onInputChange]);

    useEffect(() => {
        return () => {
            debouncedChangeHandler.clear();
        };
    }, [debouncedChangeHandler]);

    useEffect(() => {
        if (locationInfo.deliveryPlaces.length !== 0) {
            setGeoResults([locationInfo.place]);
        }
    }, [locationInfo]);

    useEffect(() => {
        if (geoSearch !== '' && geoSearch.length > 2) {
            setIsLoading(true);

            fetch(`${geoEndpoint}/search?q=${geoSearch}&limit=20&format=json&addressdetails=1`, {
                headers: { 'accept-language': 'de' },
            })
                .then(handleErrorsJson)
                .then((response) => response.json())
                .then((response: Place[]) => {
                    const filtered = filterGeoResults(response);
                    if (filtered.length === 0 && increaseTries) {
                        increaseTries();
                    }
                    setGeoResults(removeDuplicatesByName(filtered));
                    setIsLoading(false);
                })
                .catch((error) => {
                    captureException(error);
                    setError('Es ist ein Fehler aufgetreten, bitte versuche es erneut');
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoSearch]);

    const updateLocation = async (locationUpdate: Place) => {
        if (locationUpdate === undefined) return;
        try {
            trackEvent({
                action: 'Update address',
                category: 'Home',
                name: locationUpdate.customDisplayName,
            });
            const geo = await reverseGeoCode({ latitude: locationUpdate.lat, longitude: locationUpdate.lon });
            if (restaurant) {
                const foundDeliveryPace = getDeliveryPlaceForLocation(restaurant?.delivery.places, geo);
                if (foundDeliveryPace) {
                    dispatch(
                        setPostalCode({
                            deliveryPlaces: geo,
                            place: locationUpdate,
                        }),
                    );
                }
            } else {
                dispatch(
                    setPostalCode({
                        deliveryPlaces: geo,
                        place: locationUpdate,
                    }),
                );
            }

            return geo;
        } catch (error) {
            captureException(error);
        }
    };

    const validateLocation = async (value: Place): Promise<Location[]> => {
        if (value) {
            const found = await updateLocation(value);
            if (!found) return [];
            return found;
        }
        return [];
    };

    const search = async (value: Place) => {
        setError('');
        const shouldForward = location.pathname === '/' || location.pathname === '/discover';
        const valid = await validateLocation(value);
        if (valid && shouldForward) {
            history.push('/discover');
        }
        return valid;
    };

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
        id: 'adressSearch',
        options: geoResults,
        isOptionEqualToValue: (option, value) => {
            return option.customDisplayName === value.customDisplayName;
        },
        freeSolo: true,
        getOptionLabel: (option) => getPlaceDisplay(option as Place),
        filterOptions: (x) => x,
        onChange: async (selected, value) => {
            if (value === null || typeof value === 'string' || value instanceof String) return;
            const locations = await search(value);
            if (!restaurant) return;
            const foundDeliveryPace = getDeliveryPlaceForLocation(restaurant?.delivery.places, locations);
            if (onSelect && foundDeliveryPace) {
                onSelect();
            } else {
                trackEvent({
                    action: 'Address Not in delivery area',
                    category: 'Home',
                    name: `${value.customDisplayName} - ${restaurant.name}`,
                });
                setError(`${value.customDisplayName} liegt leider nicht im Liefergebiet von ${restaurant.name}`);
            }
        },
        onInputChange: debouncedChangeHandler,
        handleHomeEndKeys: true,
        open: true,
    });

    const InputProps = getInputProps();

    return (
        <div className={styles.input} {...getRootProps()}>
            <>
                <TextField
                    placeholder="Adresse, z.B. Hauptstraße 1"
                    className={styles.input}
                    size="medium"
                    autoFocus
                    color="secondary"
                    error={error !== ''}
                    helperText={error !== '' ? error : ''}
                    InputProps={{
                        inputProps: {
                            ...InputProps,
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                }
                            },
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {isLoading && <CircularProgress style={{ width: 20, height: 20 }} color="inherit" />}
                            </InputAdornment>
                        ),
                    }}
                />
                {groupedOptions.length > 0 && <div className={styles.optionsLabel}>Vorschläge</div>}
                <ul {...getListboxProps()} className={styles.listBox}>
                    {groupedOptions.map((option, index) => {
                        option = option as Place;
                        return (
                            <li {...getOptionProps({ option, index })} className={styles.option} key={option.place_id}>
                                <MdOutlinePlace /> {option.customDisplayName}
                            </li>
                        );
                    })}
                    {groupedOptions.length === 0 && geoSearch !== '' && (
                        <li className={styles.noOption}>
                            Wir konnte leider keine Ergebnisse finden, bitte überprüfe deine Eingabe.
                            {showHelp && (
                                <>
                                    <br />
                                    <br />
                                    Du kannst deine Adresse nicht finden? Keine Sorge, du kannst sie am Ende auch
                                    manuell eingeben. Wähle alternativ eine Adresse in deiner Nähe oder nutze deine
                                    Postleitzahl und Ort.
                                </>
                            )}
                        </li>
                    )}
                </ul>
            </>
        </div>
    );
};
