import { ACTION_TYPES } from './actions';
import { CartAction, CartItem } from './types';

export function addItem(item: CartItem, restaurantId: string): CartAction {
    const action: CartAction = {
        type: ACTION_TYPES.ADD_ITEM,
        restaurantId,
        item,
    };

    return action;
}
export function updateItem(item: CartItem, restaurantId: string): CartAction {
    const action: CartAction = {
        type: ACTION_TYPES.UPDATE_ITEM,
        restaurantId,
        item,
    };

    return action;
}

export function clearCart(restaurantId: string): CartAction {
    const action: CartAction = {
        type: ACTION_TYPES.CLEAR_CART,
        restaurantId,
    };

    return action;
}

export function removeItem(item: CartItem, restaurantId: string): CartAction {
    const action: CartAction = {
        type: ACTION_TYPES.REMOVE_ITEM,
        restaurantId,
        item,
    };
    return action;
}
