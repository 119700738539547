import React from 'react';

import './App.scss';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { CheckPaymentComponent } from './components/Stripe/checkApplePay';
import { store } from './redux/store';
import Agb from './screens/Agb/Agb';
import CheckoutComponent from './screens/Checkout/Checkout';
import CompletedScreen from './screens/Completed/Completed';
import Datenschutz from './screens/Datenschutz/Datenschutz';
import Entry from './screens/Entry/Entry';
import ErrorScreen from './screens/Error/ErrorScreen';
import Impressum from './screens/Impressum/Impressum';
import Info from './screens/Info/Info';
import { Login } from './screens/Login/Login';
import Main from './screens/Main/Main';
import NotFound from './screens/NotFound/NotFound';
import RateOrder from './screens/RateOrder/RateOrder';
import Registration from './screens/Registration/Registration';
import RestaurantComponent from './screens/Restaurant/Restaurant';
import { UnsubscribeScreen } from './screens/Unsubscribe/Unsubscribe';
import { version } from './util/constants';
import { matomoInstance } from './util/matomo';
import ScrollToTop from './util/scroll/scrollToTop';

Sentry.init({
    dsn: 'https://c49343239038480b86a6598d004f59e5@o1226834.ingest.sentry.io/4505228894535680',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    enabled: process.env.REACT_APP_ENV === 'production',
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: version,
});

function App(): JSX.Element {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#bf0417',
            },
            secondary: {
                main: '#26547c',
            },
        },
        typography: {
            fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
        },
    });
    const stripeKey = process.env.REACT_APP_STRIPE;
    if (!stripeKey) {
        return <></>;
    }
    const stripePromise = loadStripe(stripeKey);
    const baseName = process.env.REACT_APP_ENV === 'dev' ? '/dev' : '';
    return (
        <div className="App" id="app">
            <Helmet>
                <title>BestellEck | Jetzt Essen bestellen</title>
                <meta name="description" content="Jetzt Restaurants in deiner Nähe entdecken" />
            </Helmet>
            <MatomoProvider value={matomoInstance}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <Elements stripe={stripePromise}>
                            <Provider store={store}>
                                <CheckPaymentComponent />
                                <Router basename={baseName}>
                                    <ScrollToTop />
                                    <div className="header">
                                        <Header></Header>
                                    </div>
                                    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
                                        <Switch>
                                            <Route path="/404" component={NotFound} />
                                            <Route path="/discover" component={Main} />
                                            <Route path="/rate" component={RateOrder} />
                                            <Route path="/info" component={Info} />
                                            <Route path="/agb" component={Agb} />
                                            <Route path="/impressum" component={Impressum} />
                                            <Route path="/datenschutz" component={Datenschutz} />
                                            <Route path="/login" component={Login} />
                                            <Route path="/unsubscribe" component={UnsubscribeScreen} />
                                            <Route path="/register" component={Registration} />
                                            <Route path="/:id/completed" component={CompletedScreen} />
                                            <Route path="/:id/checkout" component={CheckoutComponent} />
                                            <Route exact path="/pizza-deluxe">
                                                <Redirect to="/pizza-deluxe-and-indian-house" />
                                            </Route>
                                            <Route exact path="/pizza-deluxe-schwetzingen">
                                                <Redirect to="/pizza-deluxe-and-indian-house" />
                                            </Route>
                                            <Route path="/:id" component={RestaurantComponent} />
                                            <Route exact path="/" component={Entry} />
                                            <Redirect to="/" />
                                        </Switch>
                                    </Sentry.ErrorBoundary>
                                    <Footer></Footer>
                                </Router>
                            </Provider>
                        </Elements>
                    </ThemeProvider>
                </StyledEngineProvider>
            </MatomoProvider>
        </div>
    );
}

export default App;
