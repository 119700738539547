import { Dispatch } from 'redux';

import { baseUrl } from '../../util/constants';

import { getRestaurantsError, getRestaurantsPending, getRestaurantsSuccess } from './restaurant.actions';
import { RestaurantAction } from './restaurant.type';

export const fetchRestaurants = (): ((dispatch: Dispatch<RestaurantAction>) => void) => {
    const url = `${baseUrl}/restaurants?useNewScheduleDays=true`;
    return (dispatch: Dispatch<RestaurantAction>) => {
        dispatch(getRestaurantsPending());
        fetch(url)
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    throw res.error;
                }
                dispatch(getRestaurantsSuccess(res));
                return res;
            })
            .catch((error: Error) => {
                dispatch(getRestaurantsError(error));
            });
    };
};
