import { OrderType } from '@bestelleck/utils';
import { AnyAction } from '@reduxjs/toolkit';

import { checkOrderType, setCheckedOrderType, setOrderType } from './orderType.actions';
import { OrderTypeState } from './types';

const initialState: OrderTypeState = {
    orderType: OrderType.PickUp,
    needToCheck: false,
    hasBeenChecked: false,
    showDialog: false,
};

const orderTypeReducer = (state: OrderTypeState = initialState, action: AnyAction): OrderTypeState => {
    if (setOrderType.match(action)) {
        const { orderType } = action.payload;
        return {
            ...state,
            orderType,
        };
    }
    if (checkOrderType.match(action)) {
        const { previousRoute, deliveryAvailable, showDialog } = action.payload;

        const isDelivery = state.orderType === OrderType.Delivery;
        const needToCheckDelivery =
            !(previousRoute.includes('discover') || previousRoute.includes('checkout')) && !isDelivery;

        const needToCheck = needToCheckDelivery && deliveryAvailable && !state.hasBeenChecked;

        return {
            ...state,
            needToCheck,
            showDialog,
            hasBeenChecked: true && showDialog,
        };
    }
    if (setCheckedOrderType.match(action)) {
        return {
            ...state,
            hasBeenChecked: true,
        };
    }
    return state;
};

export default orderTypeReducer;
