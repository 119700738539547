import React from 'react';

import { Link } from 'react-router-dom';

import styles from './NotFound.module.scss';

const NotFound: React.FC = () => (
    <div className={styles.root}>
        <h1>404 - Wir konnten die Seite leider nicht finden :(</h1>
        <Link to="/">Zurück zur Startseite</Link>
    </div>
);

export default NotFound;
