import React from 'react';

type ImgWithFallBackProps = {
    src: string;
    fallback: string;
    type?: string;
} & JSX.IntrinsicElements['img'];

export const ImgWithFallback: React.FC<ImgWithFallBackProps> = ({
    src,
    fallback,
    type = 'image/webp',
    ...delegated
}) => {
    return (
        <picture>
            <source srcSet={src} type={type} />
            <img src={fallback} {...delegated} />
        </picture>
    );
};
