import React from 'react';

import { Card, CardContent, Skeleton, useMediaQuery } from '@mui/material';

import styles from './RestaurantSkeleton.module.scss';

export const RestaurantSkeletonComponent: React.FC = () => {
    const smallScreen = useMediaQuery('(max-width:750px)');
    return (
        <>
            <div className={styles.header}>
                <div className={styles.headerText}>
                    <Skeleton animation="wave" height={50} width="150px" style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={25} width="250px" style={{ marginBottom: 6 }} />
                </div>
            </div>
            <Card className={styles.card}>
                <CardContent className={styles.content}>
                    <Skeleton animation="wave" variant="circular" width={60} height={60} />
                    <div className={styles.text}>
                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} width="80%" />
                    </div>
                </CardContent>
            </Card>
            {smallScreen && (
                <div className={styles.menu}>
                    <Skeleton animation="wave" height={20} width="20%" />
                    <Skeleton animation="wave" height={20} width="20%" />
                    <Skeleton animation="wave" height={20} width="20%" />
                </div>
            )}
            <div className={styles.placeholder}>
                <Skeleton animation="wave" height="20vh" className={styles.groupImage} />
                <Card className={styles.itemCard}>
                    <CardContent className={styles.content}>
                        <div className={styles.text}>
                            <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} width="200px" />
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} width="250px" />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </div>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};
