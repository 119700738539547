import React from 'react';

import { Card, CardContent, Skeleton } from '@mui/material';

import styles from './Skeleton.module.scss';

export type SkeletonComponentProps = {
    className: string;
};

export const SkeletonComponent: React.FC<SkeletonComponentProps> = ({ className }) => {
    return (
        <Card className={className}>
            <Skeleton sx={{ height: 160 }} animation="wave" variant="rectangular" />
            <CardContent className={styles.content}>
                <Skeleton animation="wave" variant="circular" width={60} height={60} />
                <div className={styles.text}>
                    <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={10} width="80%" />
                </div>
            </CardContent>
        </Card>
    );
};
