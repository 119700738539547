import React, { useEffect, useState } from 'react';

import { captureException } from '@sentry/react';
import { PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';

import { ApplePayProps } from './ApplePay';

export const ApplePayButton: React.FC<ApplePayProps> = ({
    price,
    getClientSecret,
    restaurantId,
    setIsLoading,
    data,
    formIsValid,
    triggerValidation,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

    useEffect(() => {
        if (!stripe || !elements) {
            return;
        }
        const pr = stripe.paymentRequest({
            country: 'DE',
            currency: 'eur',
            total: {
                label: 'BestellEck',
                amount: Math.round(price * 100),
            },
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {
            if (result) {
                setPaymentRequest(pr);
            }
            setIsLoading(false);
        });

        pr.on('paymentmethod', async (ev) => {
            const oderDetails = await getClientSecret(data);
            if (!oderDetails) return;
            const { clientSecret, orderId } = oderDetails;
            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false },
            );

            if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                ev.complete('fail');
            } else {
                history.push(`/${restaurantId}/completed?orderId=${orderId}`);
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete('success');
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (paymentIntent && paymentIntent.status === 'requires_action') {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } = await stripe.confirmCardPayment(clientSecret);
                    if (error) {
                        captureException(error);
                        // The payment failed -- ask your customer for a new payment method.
                    } else {
                        // The payment has succeeded.
                    }
                } else {
                    // The payment has succeeded.
                }
            }
        });
    }, [stripe, elements, getClientSecret, history, price, restaurantId, setIsLoading, data, formIsValid]);
    return (
        <>
            {paymentRequest && (
                <PaymentRequestButtonElement
                    onClick={(event) => {
                        if (!formIsValid) {
                            triggerValidation();
                            event.preventDefault();
                        }
                    }}
                    options={{ paymentRequest }}
                />
            )}
        </>
    );
};
