import React from 'react';

import { OrderType, RestaurantDetail } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Select } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setOrderType } from '../../../../redux/orderType/orderType.actions';
import { RootState } from '../../../../redux/store';

import styles from './DeliverySelect.module.scss';

interface DeliverySelectComponentProps {
    restaurant: RestaurantDetail | undefined;
}

export const DeliverySelectComponent: React.FC<DeliverySelectComponentProps> = ({ restaurant }) => {
    const dispatch = useDispatch();
    const { trackEvent } = useMatomo();
    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);

    return (
        <div className={styles.root}>
            <h3 style={{ marginBottom: 10 }}>Bestellart</h3>
            <Select
                native
                variant="outlined"
                className={styles.half}
                value={orderType}
                color="secondary"
                onChange={(event) => {
                    dispatch(setOrderType({ orderType: event.target.value as OrderType }));
                    trackEvent({ action: `Switch order type - ${event.target.value}`, category: 'Checkout' });
                }}
            >
                <option value={'pickup'}>Abholung</option>
                {restaurant?.delivery && <option value={'delivery'}>Lieferung</option>}
            </Select>
        </div>
    );
};
