import React from 'react';

import { Add, Remove } from '@mui/icons-material';

import { CartItem } from '../../redux/cart/types';

import styles from './NumberControl.module.scss';

interface INumberCountrol {
    itemCount: number;
    item: CartItem;
    updateItemCount: (item: any, count: number) => void;
}

const NumberControl: React.FC<INumberCountrol> = ({ itemCount, item, updateItemCount }) => {
    return (
        <div className={styles.root}>
            <div
                style={{ marginRight: 15 }}
                onClick={(event) => {
                    event.stopPropagation();
                    updateItemCount(item, -1);
                }}
            >
                <Remove />
            </div>
            <div
                onClick={(event) => {
                    event.stopPropagation();
                    if (itemCount < 99) {
                        updateItemCount(item, 1);
                    }
                }}
            >
                <Add />
            </div>
        </div>
    );
};

export default NumberControl;
