import React from 'react';

import { ImgWithFallback } from '@bestelleck/shared';
import { Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { version } from '../../util/constants';

import styles from './Footer.module.scss';

const Footer: React.FC = () => {
    return (
        <div className={styles.Footer}>
            <Card className={styles.card}>
                <CardContent>
                    <Typography variant="h6">App herunterladen</Typography>
                    <div className={styles.apps}>
                        <a
                            href="https://apps.apple.com/de/app/bestelleck/id1577427878"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/assets/ios.svg" alt="App Store" />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.slavisamarkovic.BestellEck"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <ImgWithFallback
                                src="/assets/android.webp"
                                fallback="/assets/android.png"
                                alt="Google Play"
                            />
                        </a>
                    </div>
                </CardContent>
            </Card>
            <Card className={styles.card}>
                <CardContent>
                    <Typography variant="h6">Informationen</Typography>

                    <Typography>
                        <Link to="/info">Für Restaurants</Link>
                    </Typography>
                    <p className={styles.version}>{version}</p>
                </CardContent>
            </Card>
            <Card className={styles.card}>
                <CardContent>
                    <Typography variant="h6">Rechtliches</Typography>
                    <Link to="/impressum">
                        <Typography>Impressum</Typography>
                    </Link>
                    <Typography>
                        <Link to="/datenschutz">Datenschutz</Link>
                    </Typography>
                    <Typography>
                        <Link to="/agb">AGB</Link>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default Footer;
