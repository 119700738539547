import React from 'react';

import { Close } from '@mui/icons-material';
import { DialogTitle as MuiDialogTitle, IconButton } from '@mui/material';

import styles from './DialogTitle.module.scss';

export interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

export const DialogTitle: React.FC<DialogTitleProps> = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle className={styles.root} {...other}>
            <div className={styles.placeholder}></div>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={styles.closeButton} onClick={onClose} size="large">
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};
