import React, { useEffect, useState } from 'react';

import { handleErrorsJson } from '@bestelleck/shared';
import { Order } from '@bestelleck/utils';
import {
    Button,
    CircularProgress,
    InputLabel,
    Select,
    Snackbar,
    TextField,
    useMediaQuery,
    AlertTitle,
    Rating,
    Backdrop,
    Alert as MuiAlert,
} from '@mui/material';
import { IoSadOutline } from 'react-icons/io5';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { baseUrl } from '../../util/constants';
import { CustomError } from '../../util/errors';
import { useTrackView } from '../../util/tracking/trackPage';
import { handleErrors, useQuery } from '../../util/utils';

import styles from './RateOrder.module.scss';

const Alert = React.forwardRef(function Alert(props: any, ref: any) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RateOrder: React.FC = () => {
    const query = useQuery();
    const orderId = query.get('orderId');

    useTrackView('Rate_Order');

    const [order, setOrder] = useState<Order>();
    const [error, setError] = useState({ message: '', isError: false });
    const [alreadyRatedError, setAlreadyRatedError] = useState({ message: '', isError: false });
    const [ratingError, setRatingError] = useState(false);
    const [ratingDeliveryError, setRatingDeliveryError] = useState(false);
    const [isAnonym, setIsAnonym] = useState(false);
    const [description, setDescription] = useState('');
    const [food, setFood] = useState(0);
    const [delivery, setDelivery] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [done, setDone] = useState(false);

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const history = useHistory();

    useEffect(() => {
        let timer: any;
        if (done) {
            timer = setTimeout(() => {
                history.push(`/${order?.restaurantId}`);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [done, history, order]);

    useEffect(() => {
        if (orderId && orderId !== '') {
            setIsLoading(true);
            const url = `${baseUrl}/orders/${orderId}`;
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
            fetch(url, requestOptions)
                .then(handleErrors)
                .then((res) => res.json())
                .then((result) => {
                    setOrder(result);
                    setIsLoading(false);
                })
                .catch(() => {
                    setError({
                        message: 'Die Bestellung konnte nicht gefunden werden',
                        isError: true,
                    });
                    setIsLoading(false);
                });
        }
    }, [orderId]);

    const postRating = () => {
        if (food === 0) {
            setRatingError(true);
            return;
        }
        if (order?.type === 'delivery' && delivery === 0) {
            setRatingDeliveryError(true);
            return;
        }

        setIsLoading(true);
        const url = `${baseUrl}/ratings`;
        const payload = {
            orderId,
            values: [
                {
                    category: 'food',
                    value: food,
                },
            ],
            description: description.length > 0 ? description : undefined,
            anonym: isAnonym,
        };
        if (order?.type === 'delivery') {
            payload.values.push({
                category: 'delivery',
                value: delivery,
            });
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        };

        fetch(url, requestOptions)
            .then(handleErrorsJson)
            .then((res) => res.json())
            .then(() => {
                setIsLoading(false);
                setDone(true);
            })
            .catch((error: CustomError) => {
                setError({
                    message: error.message,
                    isError: true,
                });
                setTimeout(() => {
                    setError({ message: '', isError: false });
                }, 5000);
                setIsLoading(false);
            });
    };

    if (orderId === '' || !orderId || alreadyRatedError.isError) {
        return (
            <div className={[styles.root, styles.error].join(' ')}>
                <div>
                    Ups, da ist etwas schiefgelaufen:{' '}
                    {alreadyRatedError.isError && <div>{alreadyRatedError.message}</div>}
                </div>
                <div className={styles.icon}>
                    <IoSadOutline></IoSadOutline>
                </div>
                <Link to={`/discover`}>
                    <Button type="submit" variant="contained" color="primary" className={styles.button}>
                        Zurück zur Übersicht
                    </Button>
                </Link>
            </div>
        );
    }
    if (order) {
        const createDate = new Date(order.createTime);
        const createdDay = createDate.toLocaleDateString('de-DE', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        if (order.ratingId && order.ratingId !== '') {
            setAlreadyRatedError({ isError: true, message: 'Die Bestellung wurde bereits bewertet' });
        }
        const createdTime = createDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
        const logo = `${baseUrl}/image/${order.restaurant?.avatarImageLink}`;
        return (
            <div className={styles.root}>
                <div className={styles.logo}>
                    <img src={logo} alt="Restaurant Logo"></img>
                </div>
                <div className={styles.header}>Bewerte das Restaurant: {order.restaurant?.name}</div>
                <div className={styles.header}>Deine Bestellnummer: {order.key}</div>
                <div className={styles.time}>
                    {createdDay} - {createdTime}
                </div>
                <div className={styles.rate}>
                    <div>
                        <div className={styles.subHeader}>Essen</div>
                        <Rating
                            name="no-value"
                            size="large"
                            value={food}
                            onChange={(event, newValue) => {
                                setFood(newValue as number);
                                setRatingError(false);
                            }}
                        />
                        {ratingError && <div className={styles.error}> Bitte bewerte dein Essen</div>}
                    </div>
                    {order.type === 'delivery' && (
                        <div>
                            <div className={styles.subHeader}>Lieferung</div>
                            <Rating
                                name="no-value"
                                size="large"
                                value={delivery}
                                onChange={(event, newValue) => {
                                    setDelivery(newValue as number);
                                    setRatingDeliveryError(false);
                                }}
                            />
                            {ratingDeliveryError && <div className={styles.error}> Bitte bewerte die Lieferung</div>}
                        </div>
                    )}
                </div>
                <div className={styles.name}>
                    <InputLabel>Angezeigter Bewertungsname</InputLabel>
                    <Select
                        native
                        required
                        defaultValue={'name'}
                        onChange={(event) => {
                            if (event.target.value === 'anonym') {
                                setIsAnonym(true);
                            } else {
                                setIsAnonym(false);
                            }
                        }}
                        variant="outlined"
                        className={styles.select}
                        color="secondary"
                    >
                        <option value={'name'}>{order.contact.fullName}</option>
                        <option value={'anonym'}>Anonym</option>
                    </Select>
                </div>

                <div className={styles.notes}>
                    <TextField
                        className={styles.field}
                        size="small"
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value);
                        }}
                        inputProps={{ maxLength: 1024 }}
                        multiline
                        rows={isSmallScreen ? 3 : 4}
                        id="outlined-basic"
                        label="Anmerkungen zur Bestellung"
                        variant="outlined"
                        color="secondary"
                    />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    className={styles.button}
                    disabled={done}
                    onClick={() => {
                        postRating();
                    }}
                >
                    Bewertung abschicken
                </Button>
                <Backdrop sx={{ color: '#fff' }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                    open={done}
                    autoHideDuration={2000}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                    <Alert severity="success">
                        <AlertTitle>Fertig!</AlertTitle>
                        Danke für deine Bewertung
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={error.isError}
                    autoHideDuration={2000}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                    <Alert severity="error">
                        <AlertTitle>Fehler!</AlertTitle>
                        {error.message}
                    </Alert>
                </Snackbar>
            </div>
        );
    } else {
        return (
            <div>
                Loading...{' '}
                <Backdrop sx={{ color: '#fff' }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
};

export default RateOrder;
