import { PaymentOption, RestaurantDetail, Place, Order } from '@bestelleck/utils';

import { Location, ManualEntry } from '../../types/Geo';
import { saveToLocalStorage, store } from '../store';

import {
    APP_ACTION_TYPES,
    Contact,
    ContactAction,
    FilterAction,
    FilterAttributes,
    OrderAction,
    PaymentAction,
    PaymentOptions,
    PostalCodeAction,
    RateAction,
    RestaurantAction,
    RouteAction,
    SearchAction,
    SelectedPaymentAction,
    TagAction,
} from './app.types';

export function setSelectedRestaurant(restaurant: RestaurantDetail): RestaurantAction {
    const action: RestaurantAction = {
        type: APP_ACTION_TYPES.SET_SELECTED_RESTAURANT,
        restaurant,
    };
    return action;
}

export function setCurrentRoute(route: string): RouteAction {
    const action: RouteAction = {
        type: APP_ACTION_TYPES.SET_ROUTE,
        route,
    };
    return action;
}

export function setPostalCode({
    deliveryPlaces,
    place,
    manual,
}: {
    deliveryPlaces: Location[];
    place: Place;
    manual?: ManualEntry;
}): PostalCodeAction {
    const action: PostalCodeAction = {
        type: APP_ACTION_TYPES.SET_POSTAL_CODE,
        deliveryPlaces,
        place,
        manual,
    };
    return action;
}

export function addTag(tag: string): TagAction {
    const action: TagAction = {
        tag,
        type: APP_ACTION_TYPES.ADD_TAG,
    };
    return action;
}

export function removeTag(tag: string): TagAction {
    const action: TagAction = {
        tag,
        type: APP_ACTION_TYPES.REMOVE_TAG,
    };
    return action;
}

export function setSearch(query: string): SearchAction {
    const action: SearchAction = {
        query,
        type: APP_ACTION_TYPES.SET_SEARCH,
    };
    return action;
}

export function setFilter(attribute: FilterAttributes, value: string | number): FilterAction {
    const action: FilterAction = {
        attribute,
        value,
        type: APP_ACTION_TYPES.SET_FILTER,
    };
    return action;
}

export function setOrder(order: Order): OrderAction {
    // TODO: quick workaround
    const state = JSON.parse(JSON.stringify(store.getState()));
    state.app.order = order;
    saveToLocalStorage(state);

    const action: OrderAction = {
        order,
        type: APP_ACTION_TYPES.SET_ORDER,
    };
    return action;
}

export function setContact(contact: Contact): ContactAction {
    const action: ContactAction = {
        contact,
        type: APP_ACTION_TYPES.SET_CONTACT,
    };
    return action;
}

export function setRated(): RateAction {
    const action: RateAction = {
        rated: true,
        type: APP_ACTION_TYPES.SET_RATED,
    };
    return action;
}

export function setPaymentMethods(options: PaymentOptions): PaymentAction {
    const action: PaymentAction = {
        type: APP_ACTION_TYPES.SET_PAYMENT_METHODS,
        paymentOptions: options,
    };
    return action;
}

export function setSelectedPaymentMethod(option: PaymentOption): SelectedPaymentAction {
    const action: SelectedPaymentAction = {
        type: APP_ACTION_TYPES.SET_SELECTED_PAYMENT_METHOD,
        paymentOption: option,
    };
    return action;
}
