import React from 'react';

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import styles from './ErrorDetails.module.scss';

interface ErrorDetailsProps {
    paymentOption: string;
    restaurantId: string;
}

export const ErrorDetailsComponent: React.FC<ErrorDetailsProps> = ({ paymentOption, restaurantId }) => {
    const details = [<div key={1}>Bitte versuche es noch einmal.</div>];

    if (paymentOption === 'paypal') {
        details.push(<div key={2}> Wir haben dir nichts von deinem Paypal Konto abgebucht.</div>);
    }

    return (
        <>
            <div>Ups, da ist was schiefgelaufen.</div>
            {details}
            <Link to={`/${restaurantId}/checkout`}>
                <Button variant="contained" color="primary" className={styles.button}>
                    Zurück zur Bestellung
                </Button>
            </Link>
        </>
    );
};
