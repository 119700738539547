import React, { useEffect, useState } from 'react';

import {
    Dialog,
    Divider,
    Theme,
    DialogContent as MuiDialogContent,
    Rating as RatingComponent,
    useMediaQuery,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { captureException } from '@sentry/react';

import { DialogTitle } from '../../../../components/DialogTitle/DialogTitle';
import { Rating } from '../../../../types/Restaurant';
import { baseUrl } from '../../../../util/constants';
import { formatNumber } from '../../../../util/utils';

import RatingCardComponent from './RatingCard/RatingCard';
import styles from './Ratings.module.scss';
import RatingSkeletonComponent from './RatingSkeleton/RatingSkeleton';

export type RatingsDialogProps = {
    open: boolean;
    handleClose: () => void;
    ratingStats: { average: number; count: number };
    restaurantId: string;
};

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const RatingsDialogComponent: React.FC<RatingsDialogProps> = ({ open, handleClose, ratingStats, restaurantId }) => {
    const [ratings, setRatings] = useState<Rating[]>();
    const smallScreen = useMediaQuery('(max-width:750px)');

    const ratingsUrl = `${baseUrl}/ratings/${restaurantId}`;
    useEffect(() => {
        fetch(ratingsUrl)
            .then((res) => res.json())
            .then(
                (result) => {
                    setRatings(result);
                },
                (error) => {
                    captureException(error);
                },
            );
    }, [ratingsUrl]);

    if (ratings && ratings?.length > 0) {
        const ratingElements = ratings.map((rating) => {
            return <RatingCardComponent key={rating.id} rating={rating}></RatingCardComponent>;
        });

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={smallScreen}
                fullWidth={true}
                maxWidth={'sm'}
                PaperProps={{
                    style: {
                        backgroundColor: '#f7f7f7',
                    },
                }}
            >
                <DialogTitle id="rating-dialog-title" onClose={handleClose}>
                    Bewertungen
                </DialogTitle>
                <DialogContent dividers>
                    <div className={styles.root}>
                        <h4>Durchschnittliche Bewertung</h4>
                        <div className={styles.rating}>
                            <h2>{formatNumber(ratingStats.average)}</h2>
                            <Divider flexItem orientation="vertical" />
                            <div>
                                <RatingComponent
                                    readOnly
                                    name="rating-Readonly"
                                    size="large"
                                    value={ratingStats.average}
                                    precision={0.5}
                                />
                                <div className={styles.count}>{ratingStats.count} Bewertungen</div>
                            </div>
                        </div>
                    </div>
                    {ratingElements}
                </DialogContent>
            </Dialog>
        );
    } else {
        return (
            <Dialog open={open} onClose={handleClose} fullScreen={smallScreen} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="rating-dialog-title" onClose={handleClose}>
                    Bewertungen
                </DialogTitle>
                <DialogContent dividers>
                    <div className={styles.root}>
                        <RatingSkeletonComponent />
                        <RatingSkeletonComponent />
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
};

export default RatingsDialogComponent;
