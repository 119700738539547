import React, { Dispatch } from 'react';

import { OrderType } from '@bestelleck/utils';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Rating } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setFilter } from '../../../../redux/app/app.actions';
import { RootState } from '../../../../redux/store';

import styles from './FilterComponent.module.scss';

export const FilterComponent: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);
    const selectedFilters = useSelector((state: RootState) => state.app.filters, shallowEqual);
    const isDelivery = orderType === OrderType.Delivery;
    const handleMinOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setFilter('minimumOrderValue', Number(event.target.value)));
    };

    const handleDeliveryCost = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setFilter('deliveryCost', Number(event.target.value)));
    };

    const handleRating = (event: any, newValue: number | null) => {
        if (newValue === null) newValue = 0;
        dispatch(setFilter('rating', newValue));
    };

    const handlePayment = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setFilter('payment', (event.target as HTMLInputElement).value));
    };

    return (
        <div className={styles.Filter}>
            <div className={styles.filterContainer}>
                <div className={styles.form}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" className={styles.formLabel}>
                            Bewertungen
                        </FormLabel>
                        <Rating
                            className={styles.rating}
                            name="ratingFilter"
                            value={Number(selectedFilters.rating)}
                            onChange={handleRating}
                        />
                    </FormControl>
                </div>
                <div className={styles.form}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" className={styles.formLabel}>
                            Bezahlmöglichkeiten
                        </FormLabel>
                        <RadioGroup
                            aria-label="rating"
                            name="rating"
                            value={selectedFilters.payment}
                            onChange={handlePayment}
                        >
                            <FormControlLabel
                                value="none"
                                control={<Radio size="small" color="primary" />}
                                label="Keine Präferenz"
                            />
                            <FormControlLabel
                                value="cash"
                                control={<Radio size="small" color="primary" />}
                                label="Barzahlung"
                            />
                            <FormControlLabel
                                value="online"
                                control={<Radio size="small" color="primary" />}
                                label="Onlinezahlung"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>

            {isDelivery && (
                <div className={styles.filterContainer}>
                    <div className={styles.form}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={styles.formLabel}>
                                Mindestbestellwert
                            </FormLabel>
                            <RadioGroup
                                aria-label="minOrder"
                                name="minOrder"
                                value={selectedFilters.minimumOrderValue}
                                onChange={handleMinOrder}
                            >
                                <FormControlLabel
                                    value={-1}
                                    control={<Radio size="small" color="primary" />}
                                    label="Keine Präferenz"
                                />
                                <FormControlLabel
                                    value={10}
                                    control={<Radio size="small" color="primary" />}
                                    label="10€ oder weniger"
                                />
                                <FormControlLabel
                                    value={20}
                                    control={<Radio size="small" color="primary" />}
                                    label="20€ oder weniger"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className={styles.form}>
                        <FormControl component="fieldset" className={styles.form}>
                            <FormLabel component="legend" className={styles.formLabel}>
                                Lieferkosten
                            </FormLabel>
                            <RadioGroup
                                aria-label="minOrder"
                                name="minOrder"
                                value={selectedFilters.deliveryCost}
                                onChange={handleDeliveryCost}
                            >
                                <FormControlLabel
                                    value={-1}
                                    control={<Radio size="small" color="primary" />}
                                    label="Keine Präferenz"
                                />
                                <FormControlLabel
                                    value={0}
                                    control={<Radio size="small" color="primary" />}
                                    label="Gratis"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={<Radio size="small" color="primary" />}
                                    label="1€ oder weniger"
                                />
                                <FormControlLabel
                                    value={2.5}
                                    control={<Radio size="small" color="primary" />}
                                    label="2,50€ oder weniger"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            )}
        </div>
    );
};
