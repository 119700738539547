import React, { useCallback } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { Redirect, useLocation } from 'react-router';

export const Login: React.FC = () => {
    const location = useLocation<{ [key: string]: unknown }>();
    const currentLocationState = location.state || {
        from: { pathname: '/home' },
    };

    const { keycloak } = useKeycloak();

    const login = useCallback(() => {
        keycloak?.login();
    }, [keycloak]);

    if (keycloak?.authenticated) {
        return <Redirect to={currentLocationState?.from as string} />;
    }

    return (
        <div>
            <button type="button" onClick={login}>
                Login
            </button>
        </div>
    );
};
