import React from 'react';

import { ImgWithFallback } from '@bestelleck/shared';

interface PlaceHolderImageProps {
    className?: string;
    style?: React.CSSProperties;
}

export const ImagePlaceHolder: React.FC<PlaceHolderImageProps> = ({ className, style }) => {
    return (
        <ImgWithFallback
            className={className}
            style={style}
            src="assets/LoadingWhite.webp"
            fallback="assets/LoadingWhite.png"
        />
    );
};
