import { createInstance } from '@datapunt/matomo-tracker-react';

export const matomoInstance = createInstance({
    urlBase: 'https://tracking.bestelleck.de',
    siteId: 1,
    disabled: process.env.REACT_APP_ENV !== 'production',
    heartBeat: {
        active: true,
        seconds: 15,
    },
    linkTracking: false,
    configurations: {
        // optional, default value: {}
        disableCookies: true,
        //setSecureCookie: true,
        setRequestMethod: 'POST',
    },
});
