import React from 'react';

import { Close, Menu } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Link } from 'react-router-dom';

import styles from './Drawer.module.scss';

export type DrawerProps = {
    entries: { link: string; name: string }[];
};

const Drawer: React.FC<DrawerProps> = ({ entries }) => {
    const [state, setState] = React.useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };

    const list = () => (
        <div className={styles.List} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
                <ListItem button key={1} className={styles.Header}>
                    <div className={styles.logo}>
                        <p className={styles.titleText}>BESTELL</p>
                        <p className={styles.titleText + ' ' + styles.grey}>ECK</p>
                        <div className={styles.logoImg}></div>
                    </div>
                    <Close />
                </ListItem>
                {entries.map((entry, index) => (
                    <div key={index + 2}>
                        <ListItem button key={index + 2} component={Link} to={entry.link}>
                            <ListItemText primary={entry.name} />
                        </ListItem>
                        {index <= entries.length && <Divider className={styles.divider} />}
                    </div>
                ))}
            </List>
        </div>
    );

    return (
        <div className={styles.container}>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)} size="large">
                <Menu />
            </IconButton>
            <SwipeableDrawer anchor={'top'} open={state} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                {list()}
            </SwipeableDrawer>
        </div>
    );
};

export default Drawer;
