import React from 'react';

import styles from './Agb.module.scss';

const Agb: React.FC = () => (
    <div lang={'de'} className={styles.Agb}>
        <h1 className={styles.headerText}>{'Allgemeine Geschäftsbedingungen'}</h1>
        <h2 className={styles.headerText}>1. Geschäftsgegenstand</h2>
        <p>
            BestellEck stellt eine Online-Plattform zur Abwicklung von Bestellungen bei Restaurants bereit. Der Kunde
            (Nutzer der Webseite oder App) kann über BestellEck Speisen und Getränke bei verfügbaren Restaurants
            bestellen. BestellEck nimmt dabei die Bestellung entgegen, speichert die Daten und übermittelt die
            Bestellung an das Restaurant.
        </p>
        <h2 className={styles.headerText}>2. Geltungsbereich</h2>
        <p>
            Die Allgemeine Geschäftsbedingungen gelten für alle Bestellungen, die über BestellEck abgegeben werden.
            Durch das Abschicken einer Bestellung schließt der Kunden einen verbindlichen Vertrag mit dem Restaurant ab.
            BestellEck ist nicht für das Angebot und den Vertrag zwischen dem Kunden und dem Restaurant verantwortlich.
        </p>
        <h2 className={styles.headerText}>3. Angebot</h2>
        <p>
            Das Restaurant ist für die bereitgestellten Informationen auf der Online-Plattform zuständig und BestellEck
            handelt nicht im Namen der Restaurants. Die Restaurantinformationen werden von BestellEck nicht auf
            Richtigkeit oder Vollständigkeit überprüft und BestellEck ist nicht für die Durchführung des Vertrages
            zwischen dem Kunden und dem Restaurant verantwortlich.
        </p>
        <p>
            Ein Restaurant hat die Möglichkeit, Zutaten und Zusatzstoffe für Speisen und Getränke einzutragen. Um
            weitere Informationen zu den Zutaten und den Zusatzstoffen zu erhalten, wenden Sie sich an das Restaurant.
        </p>
        <h2 className={styles.headerText}>4. Vertragliche Leistung</h2>
        <p>
            BestellEck stellt lediglich die Online-Plattform zur Verfügung und vermittelt eine Bestellung vom Kunden an
            das Restaurant. Für den Kaufvertrag zwischen dem Kunden und dem Restaurant übernimmt BestellEck keine
            Gewährleistung.
        </p>
        <p>
            Der Vertrag zwischen dem Kunden und dem Restaurant kommt verbindlich zustanden, sobald der Kunde die
            Bestellung über die Online-Plattform aufgibt.
        </p>
        <p>
            Die Bestellung vom Kunden wird an das Restaurant weitergeleitet. Der Kunde ist für die Bereitstellung
            korrekter und vollständiger Kontaktdaten zuständig und hat bei Fehlern das Restaurant unverzüglich darüber
            zu informieren. Hierzu sollte der Kunde sich telefonisch an das Restaurant wenden. Der Kunde muss
            telefonisch unter der angegebenen Telefonnummer erreichbar sein.
        </p>
        <p>
            Das Restaurant prüft die Bestellung und ist berechtigt, Bestellungen abzulehnen, wenn das Angebot nicht mehr
            verfügbar ist, der Kunde falsche Informationen angegeben hat oder wenn ein Fall von höherer Gewalt vorliegt.
        </p>
        <p>
            Sobald das Restaurant die Bestellung angenommen oder abgelehnt hat, wird der Kunde elektronisch darüber
            informiert.
        </p>
        <p>
            Wurde Lieferung als Bestellart gewählt, so muss der Kunde an der angegebenen Lieferadresse anwesend sein.
            Ist die Bestellart Abholung gewählt worden, so hat der Kunde die Bestellung zum genannten Zeitpunkt und
            Abholungsort des Restaurants abzuholen.
        </p>
        <p>
            Wurden Produkte, die eine Altersgrenze enthalten, bestellt (z.B. alkoholische Getränke), so kann das
            Restaurant die Vorlage einer Altersidentifizierung fordern. Kann sich der Kunde nicht entsprechend ausweisen
            oder erfüllt er nicht die Altersanforderungen, kann das Restaurant die Aushändigung des Produktes an den
            Kunden verweigern und Stornokosten in Höhe des Kaufpreises für das Produkt mit Altersgrenze in Rechnung
            stellen.
        </p>
        <p>
            BestellEck übernimmt keine Haftung für die Ausführung des Vertrages. Bei Fragen oder Beschwerden zu einer
            Bestellung ist das Restaurant direkt zu kontaktieren. Darüber hinaus kann auch eine Beschwerde über ein
            Restaurant an BestellEck unter kontakt@bestelleck.de geschickt werden.
        </p>
        <h2 className={styles.headerText}>5. Ablehnung von Bestellungen</h2>
        <p>
            Aufgrund der Verderblichkeit von Speisen und Getränken kann der Vertrag nicht von einem Kunden aufgelöst
            werden. Eine Stornierung der Bestellung gegenüber dem Restaurant ist ebenfalls nicht möglich.
        </p>
        <p>
            Das Restaurant ist berechtigt, Bestellungen abzulehnen, wenn das Angebot nicht mehr verfügbar ist, der Kunde
            falsche Informationen angegeben hat oder wenn ein Fall von höherer Gewalt vorliegt. Das Restaurant kann auch
            Bestellungen von einem Kunden sperren, wenn entsprechende Gründe vorliegen.
        </p>
        <h2 className={styles.headerText}>6. Ablehnung, Änderung, Löschung und Ausschluss</h2>
        <p>
            BestellEck behält sich jederzeit das Recht (ohne Angaben von Gründen) vor Inhalte oder Angebote, die gegen
            geltendes Recht verstoßen oder im Widerspruch zu den Allgemeinen Geschäftsbedingungen stehen, Kunden
            abzulehnen, ganz oder teilweise zu löschen bzw. zu sperren. Restaurants können in diesem Fall dauernd oder
            temporär von der Online-Plattform von BestellEck ausgeschlossen werden.
        </p>
        <h2 className={styles.headerText}>7. Gewährleistung</h2>
        <p>
            Mängel bzw. Reklamationen können nur sofort bei der Anlieferung bzw. Abholung geltend gemacht werden und
            sind direkt an das Restaurant zu richten. Ansprüche wegen Mängeln von Lieferungen und Leistungen richten
            sich nach den Vorschriften des BGB.
        </p>
        <h2 className={styles.headerText}>8. Widerrufsrecht</h2>
        <p>
            Der Kunde kann Bestellungen gegenüber dem Restaurant nicht widerrufen, wenn einer der folgenden Punkte
            zutrifft:
        </p>
        <ul>
            <li>
                Waren (Speisen und Getränke), die nicht vorgefertigt oder auf die persönlichen Bedürfnisse der
                Verbraucher zugeschnitten sind.
            </li>
            <li>Waren (Speisen und Getränke), die aufgrund ihrer Zubereitung schnell verderben können.</li>
            <li>
                Waren (Speisen und Getränke), die aufgrund von hygienischen oder gesundheitlichen Aspekten nicht zur
                Rückgabe geeignet sind.
            </li>
            <li>Waren (Speisen und Getränke), die sich mit anderen Waren untrennbar vermischt haben.</li>
        </ul>
        <p>
            Für Bestellungen, die nicht aufgrund der oben genannten Gründen aus dem Widerrufsrecht ausgeschlossen sind,
            steht dem Kunden ein Widerrufsrecht zu.
        </p>
        <p>
            Um den Vertrag zu widerrufen, senden Sie uns eine Nachricht an BestellEck UG (haftungsbeschränkt), Mülhauser
            Straße 27, 68229 Mannheim oder per Mail an kontakt@bestelleck.de.
        </p>
        <h2 className={styles.headerText}>9. Haftung</h2>
        <p>
            BestellEck haftet nicht für das Angebot, die Richtigkeit und Vollständigkeit der Restaurantinformationen,
            die Durchführung des Vertrags und/oder die Erreichbarkeit der Plattform.
        </p>
        <h2 className={styles.headerText}>10. Sonstiges</h2>
        <p>Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.</p>
        <p>
            Sollte eine der Bestimmungen dieser AGB ganz oder teilweise unwirksam sein oder werden, so wird hiervon
            nicht die Wirksamkeit der übrigen Bestimmungen berührt.
        </p>
        <p>
            <br></br>Stand: 15.08.2021
        </p>
    </div>
);

export default Agb;
