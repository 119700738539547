import React from 'react';

import { formatPrice } from '@bestelleck/shared';
import { Coupon, CouponType, DeliveryPlace, OrderType } from '@bestelleck/utils';
import { Divider } from '@mui/material';

import { CartItem } from '../../../../redux/cart/types';
import { calculatePrice, calculatePriceForItem } from '../../../../util/utils';

import styles from './CartOverview.module.scss';

interface CartOverviewProps {
    orderType: OrderType;
    delivery?: DeliveryPlace;
    items: readonly CartItem[];
    coupon?: Coupon;
    menuCoupon?: Pick<Coupon, 'id' | 'minimumOrderValue' | 'modifier'>;
    showItems?: boolean;
}

export const CartOverview: React.FC<CartOverviewProps> = ({
    delivery,
    items,
    orderType,
    menuCoupon,
    coupon,
    showItems,
}) => {
    const isDelivery = orderType === OrderType.Delivery;
    const deliveryPrice = delivery ? delivery.fee : 0;
    const { price, discount, subtotal, deliveryFee, couponDiscount } = calculatePrice({
        items,
        orderType,
        deliveryCost: deliveryPrice,
        menuCoupon,
        feeThreshold: delivery?.freeThreshold,
        coupon,
    });

    let menuCouponDisplay;
    if (menuCoupon && menuCoupon.modifier.type === CouponType.Fixed) {
        menuCouponDisplay = `${formatPrice(menuCoupon.modifier.value)}`;
    } else if (menuCoupon && menuCoupon.modifier.type === CouponType.Percentage) {
        menuCouponDisplay = `${menuCoupon.modifier.value}%`;
    }

    let couponDisplay;
    if (coupon && coupon.modifier.type === CouponType.Fixed) {
        couponDisplay = `${formatPrice(coupon.modifier.value)}`;
    } else if (coupon && coupon.modifier.type === CouponType.Percentage) {
        couponDisplay = `${coupon.modifier.value}%`;
    }

    const displayItems = items.map((item) => {
        const optionsDisplay = item.options.map((option) => option.selectedValue.name);
        const extraDisplay = item.extraGroups.map((extraGroup) => {
            const extras = extraGroup.extras.map((extra) => extra.name).join(', ');
            if (extras !== '') {
                return (
                    <div className={styles.extras} key={extraGroup.name}>
                        <span className={styles.label}>{extraGroup.name}: </span>
                        {extras}
                    </div>
                );
            }
            return <div key={extraGroup.name}></div>;
        });
        return (
            <div key={item.id} className={styles.item}>
                <div className={styles.wrapper}>
                    <div className={styles.amount}>
                        <div>{item.amount}x</div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.text}>{item.name}</div>
                        <div className={styles.details}>
                            <div className={styles.options}>
                                {optionsDisplay.length > 0 && (
                                    <div>
                                        <span className={styles.bold}>Auswahl: </span> {optionsDisplay.join(', ')}
                                    </div>
                                )}
                            </div>
                            <div className={styles.extras}>{extraDisplay.length > 0 && <div>{extraDisplay}</div>}</div>
                            <div className={styles.extras}>
                                {item.note !== '' && (
                                    <div>
                                        <span className={styles.bold}>Anmerkung: </span> {item.note}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.price}>{formatPrice(calculatePriceForItem(item, isDelivery))}</div>
            </div>
        );
    });

    return (
        <div className={styles.root}>
            {showItems && (
                <>
                    <div className={styles.items}>{displayItems}</div>
                    <Divider />
                </>
            )}

            <div className={styles.row}>
                <span>Bestellsumme: </span>
                <span>{formatPrice(subtotal)}</span>
            </div>
            {discount > 0 && (
                <div className={styles.row}>
                    <span>Rabatt ({menuCouponDisplay}):</span> <span>- {formatPrice(discount)}</span>
                </div>
            )}
            {couponDiscount > 0 && (
                <div className={styles.row}>
                    <span>
                        Gutschein ({coupon?.key} - {couponDisplay}):
                    </span>
                    <span>- {formatPrice(couponDiscount)}</span>
                </div>
            )}
            {discount > 0 && isDelivery && (
                <div className={styles.row}>
                    <span className={styles.subtotal}>Zwischensumme:</span>{' '}
                    <span>{formatPrice(subtotal - discount - couponDiscount)}</span>
                </div>
            )}
            {isDelivery && (
                <div className={styles.row}>
                    <span>Lieferkosten:</span> <span>{formatPrice(deliveryFee)}</span>
                </div>
            )}
            {isDelivery &&
                delivery?.freeThreshold !== undefined &&
                delivery.freeThreshold > 0 &&
                subtotal - discount < delivery.freeThreshold && (
                    <div className={styles.feeThreshold}>
                        Kostenlose Lieferung ab {formatPrice(delivery.freeThreshold)}
                    </div>
                )}
            <div className={styles.row}>
                <span className={styles.total}>Gesamt:</span> <span>{formatPrice(price)}</span>
            </div>
        </div>
    );
};
