import React from 'react';

import { DialogTitle } from '@bestelleck/shared';
import { Dialog, DialogContent } from '@mui/material';

import styles from './ImageDialog.module.scss';

type ImageDialogComponentProps = {
    image: string;
    name: string;
    open: boolean;
    onClose: (event: any) => void;
};

const ImageDialogComponent: React.FC<ImageDialogComponentProps> = ({ onClose, open, image, name }) => {
    return (
        <div>
            <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
                <DialogTitle id="customized-dialog-title" onClose={onClose}>
                    {name}
                </DialogTitle>
                <DialogContent
                    className={styles.root}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <img className={styles.image} alt="" src={image}></img>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ImageDialogComponent;
