import React, { useState } from 'react';

import { Extra, ExtraGroup, MenuItem, MenuItemOption, OrderType } from '@bestelleck/utils';
import { ArrowBackIosNew, Close, InfoOutlined, Message } from '@mui/icons-material';
import {
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    InputAdornment,
    Slide,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '../../../../redux/store';
import { getInitalExtras, getInitialOptions } from '../MenuItem.utils';

import { MenuItemDetailsComponent } from './MenuItemDetails/MenuItemDetails';
import styles from './MenuItemDialog.module.scss';
import { MenuItemFooter } from './MenuItemFooter/MenuItemFooter';
import { ProductInfoDialogComponent } from './ProductInfo/ProductInfo';

type MenuItemDialogProps = {
    open: boolean;
    isAvailable: boolean;
    extras: Extra[];
    item: MenuItem;
    selectionGroups: (MenuItemOption | ExtraGroup)[];
    handleClose: () => void;
};

export const MenuItemDialog: React.FC<MenuItemDialogProps> = ({
    extras,
    handleClose,
    open,
    item,
    isAvailable,
    selectionGroups,
}) => {
    const smallScreen = useMediaQuery('(max-width:750px)');
    const [showInfo, setShowInfo] = React.useState(false);
    const [direction, setDirection] = React.useState<'up' | 'down' | 'left' | 'right' | undefined>();

    /*let imageLink;
    if (item.imageLink !== '') {
        //imageLink = `${baseUrl}/image/${item.imageLink}`;
        //imageLinkSmall = `${imageLink}?height=300`;
    }*/

    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);

    const isDelivery = orderType === OrderType.Delivery;

    const { id: restaurantId } = useParams<{ id: string }>();
    const initialOptions = getInitialOptions(item, selectionGroups, isDelivery);
    const [selectedOptions, setOptions] = useState(initialOptions);
    const [note, setNote] = useState('');

    const initalExtras = getInitalExtras(item, selectionGroups, extras, initialOptions);

    const [selectedExtras, setExtras] = useState(initalExtras);

    return (
        <div className={styles.root}>
            <Dialog
                onClose={() => handleClose()}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                fullScreen={smallScreen}
                maxWidth="sm"
            >
                <div id="customized-dialog-title" className={styles.header}>
                    {showInfo && (
                        <div className={styles.backButton}>
                            <IconButton
                                aria-label="close"
                                className={styles.backButton}
                                onClick={() => setShowInfo(false)}
                                size="small"
                            >
                                <ArrowBackIosNew />
                            </IconButton>
                        </div>
                    )}
                    <Typography component="h5" variant="h5">
                        {item.name}
                        {!showInfo && (
                            <InfoOutlined
                                className={styles.info}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setShowInfo(true);
                                    setDirection('right');
                                    event.stopPropagation();
                                }}
                            />
                        )}
                    </Typography>
                    <div className={styles.closeButton}>
                        <IconButton
                            aria-label="close"
                            className={styles.closeButton}
                            onClick={() => handleClose()}
                            size="small"
                        >
                            <Close />
                        </IconButton>
                    </div>
                </div>

                <DialogContent dividers className={styles.dialog}>
                    {!showInfo && (
                        <Slide direction={direction} in={!showInfo} appear={!!direction} mountOnEnter unmountOnExit>
                            <MenuItemDetailsComponent
                                extras={extras}
                                initalExtras={initalExtras}
                                isDelivery={isDelivery}
                                selectedExtras={selectedExtras}
                                selectedOptions={selectedOptions}
                                setExtras={setExtras}
                                setOptions={setOptions}
                                item={item}
                                selectionGroups={selectionGroups}
                            />
                        </Slide>
                    )}

                    {showInfo && (
                        <Slide direction="left" in={showInfo} timeout={300} mountOnEnter unmountOnExit>
                            <ProductInfoDialogComponent item={item} />
                        </Slide>
                    )}
                    <TextField
                        className={styles.note}
                        id="note"
                        label="Anmerkung"
                        value={note}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNote(event.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Message />
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>
                {!showInfo && (
                    <DialogActions className={styles.actions}>
                        <MenuItemFooter
                            handleClose={() => handleClose()}
                            setExtras={setExtras}
                            initialSelectedExtras={initalExtras}
                            isDelivery={isDelivery}
                            item={item}
                            restaurantId={restaurantId}
                            selectedExtras={selectedExtras}
                            selectedOptions={selectedOptions}
                            isAvailable={isAvailable}
                            note={note}
                        />
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
};
