import React from 'react';

import { Skeleton } from '@mui/material';

import styles from './RatingSkeleton.module.scss';

const RatingSkeletonComponent: React.FC = () => {
    return (
        <div className={styles.root}>
            <Skeleton width={150} height={30} />
            <Skeleton width={200} height={20} />
            <div className={styles.space}>
                <Skeleton width={100} height={30} />
                <Skeleton width={100} height={30} />
            </div>

            <Skeleton height={20} />
            <Skeleton height={20} />
        </div>
    );
};

export default RatingSkeletonComponent;
