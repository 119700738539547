import { PaymentOption, RestaurantDetail, Place, Order } from '@bestelleck/utils';

import { Location, ManualEntry } from '../../types/Geo';

export type AppState = {
    selectedTags: string[];
    search: string;
    location: {
        deliveryPlaces: Location[];
        place: Place;
        manual?: ManualEntry;
    };
    filters: {
        rating: string;
        payment: PaymentOption | 'none';
        minimumOrderValue: number;
        deliveryCost: number;
    };
    contact: Contact;
    order: Order | null;
    selectedRestaurant: RestaurantDetail | undefined;
    version: number;
    cartVersion: number;
    route: {
        previous: string;
        current: string;
    };
    hasRated: boolean;
    paymentOptions: PaymentOptions;
    selectedPaymentOption: PaymentOption;
};

export type Contact = {
    name?: string;
    email?: string;
    phone?: string;
    address?: string;
    city?: string;
};

export type PostalCodeAction = {
    type: string;
    deliveryPlaces: Location[];
    place: Place;
    manual?: ManualEntry;
};

export type TagAction = {
    type: string;
    tag: string;
};

export type RouteAction = {
    type: string;
    route: string;
};

export type SearchAction = {
    type: string;
    query: string;
};

export type RestaurantAction = {
    type: string;
    restaurant: RestaurantDetail;
};

export type OrderAction = {
    type: string;
    order: Order;
};

export type ContactAction = {
    type: string;
    contact: Contact;
};

export type RateAction = {
    type: string;
    rated: boolean;
};

export type FilterAttributes = 'rating' | 'deliveryCost' | 'minimumOrderValue' | 'payment';

export type FilterAction = {
    type: string;
    attribute: FilterAttributes;
    value: string | number;
};

export type PaymentOptions = {
    google: boolean;
    apple: boolean;
    link: boolean;
};

export type PaymentAction = {
    type: string;
    paymentOptions: PaymentOptions;
};

export type SelectedPaymentAction = {
    type: string;
    paymentOption: PaymentOption;
};

export type AppAction =
    | TagAction
    | RestaurantAction
    | PostalCodeAction
    | SearchAction
    | FilterAction
    | OrderAction
    | ContactAction
    | RouteAction
    | RateAction
    | PaymentAction
    | SelectedPaymentAction;

export type DeliveryDispatchType = (args: AppAction) => AppAction;

export enum APP_ACTION_TYPES {
    SET_POSTAL_CODE = 'SET_POSTAL_CODE',
    ADD_TAG = 'ADD_TAG',
    REMOVE_TAG = 'REMOVE_TAG',
    SET_SELECTED_RESTAURANT = 'SET_SELECTED_RESTAURANT',
    SET_SEARCH = 'SET_SEARCH',
    SET_FILTER = 'SET_FILTER',
    SET_ORDER = 'SET_ORDER',
    SET_CONTACT = 'SET_CONTACT',
    SET_ROUTE = 'SET_ROUTE',
    SET_RATED = 'SET_RATED',
    SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS',
    SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD',
}
