import React, { useState } from 'react';

import { Skeleton } from '@mui/material';

type ImageProps = {
    src: string;
    className?: string;
    isRound?: boolean;
};

const Image: React.FC<ImageProps> = ({ src, isRound = false, className = '' }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const variant = isRound ? 'circular' : 'rectangular';
    const imageStyle = !isLoaded ? { display: 'none' } : {};
    return (
        <>
            {!isLoaded && <Skeleton className={className} animation="wave" variant={variant} />}
            <img className={className} src={src} style={imageStyle} onLoad={() => setIsLoaded(true)} />
        </>
    );
};

export default React.memo(Image);
