import React, { useState } from 'react';

import { DialogTitle, formatPrice } from '@bestelleck/shared';
import { Button, Skeleton, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { captureException } from '@sentry/react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import { BusyIndicatorComponent } from '../../../../components/BusyIndicator/BusyIndicator';

import styles from './StripeDialog.module.scss';

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

type StripeDialogProps = {
    open: boolean;
    handleClose: () => void;
    paymentMethod: string;
    orderId: string;
    restaurantId: string;
    price: number;
};

export const StripeDialog: React.FC<StripeDialogProps> = ({
    open,
    handleClose,
    paymentMethod,
    orderId,
    restaurantId,
    price,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();
    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const smallScreen = useMediaQuery('(max-width:750px)');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(undefined);
        if (!stripe || !elements) {
            return;
        }
        setIsLoading(true);
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/${restaurantId}/completed?orderId=${orderId}`,
            },
        });
        setIsLoading(false);
        if (result.error) {
            const code = result.error.code || '';
            if (code !== 'incomplete_expiry' && code !== 'incomplete_cvc' && code !== 'incomplete_number') {
                setError(result.error.message);
                captureException(result.error);
            }
        } else {
            setError(undefined);
        }
    };
    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                fullScreen={smallScreen}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Mit {t('payment.' + paymentMethod)} bezahlen
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>
                        <PaymentElement
                            onReady={() => {
                                setIsReady(true);
                            }}
                        />
                        {!isReady && (
                            <div>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </div>
                        )}
                        {error && <div className={styles.error}>Fehler: {error}</div>}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!stripe || !isReady}
                            className={styles.orderButton}
                        >
                            Jetzt bezahlen ({formatPrice(price)})
                        </Button>
                    </form>
                    <BusyIndicatorComponent isLoading={isLoading} />
                </DialogContent>
            </Dialog>
        </div>
    );
};
