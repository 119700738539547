import { deepEqual } from '../../util/utils';

import { ACTION_TYPES } from './actions';
import { CartAction, CartItemAction, CartState } from './types';

const initialState: CartState = {
    carts: [],
};

const cartReducer = (state: CartState = initialState, action: CartAction): CartState => {
    const restaurantCart = state.carts.find((cart) => cart.restaurantId === action.restaurantId);
    switch (action.type) {
        case ACTION_TYPES.ADD_ITEM:
            const addAction = action as CartItemAction;
            if (restaurantCart) {
                const carts = state.carts.filter((cart) => cart.restaurantId !== addAction.restaurantId);

                let items = [...restaurantCart.items];

                const existing = items.findIndex((item) => {
                    const { cartId: _, amount, ...item1 } = item;
                    const { cartId: __, amount: amount2, ...item2 } = addAction.item;
                    return deepEqual(item1, item2);
                });
                if (existing !== -1) {
                    items[existing].amount += addAction.item.amount;
                } else {
                    items = [...items, addAction.item];
                }

                const newCart = {
                    restaurantId: addAction.restaurantId,
                    items: [...items],
                };
                return {
                    carts: [...carts, newCart],
                };
            }
            const newCart = {
                items: [addAction.item],
                restaurantId: addAction.restaurantId,
            };
            return {
                carts: [...state.carts, newCart],
            };
        case ACTION_TYPES.UPDATE_ITEM:
            const updateAction = action as CartItemAction;
            if (restaurantCart) {
                const newCart = {
                    items: restaurantCart.items.map((item) => {
                        if (item.cartId === updateAction.item.cartId) {
                            return updateAction.item;
                        }
                        return item;
                    }),
                    restaurantId: updateAction.restaurantId,
                };
                const carts = state.carts.filter((cart) => cart.restaurantId !== updateAction.restaurantId);
                return {
                    carts: [...carts, newCart],
                };
            }
            return state;
        case ACTION_TYPES.REMOVE_ITEM:
            const removeAction = action as CartItemAction;
            if (restaurantCart) {
                const items = restaurantCart.items.filter((item) => item.cartId !== removeAction.item.cartId);
                const carts = state.carts.filter((cart) => cart.restaurantId !== removeAction.restaurantId);
                const newCart = {
                    items: [...items],
                    restaurantId: removeAction.restaurantId,
                };
                return {
                    carts: [...carts, newCart],
                };
            }
            return state;
        case ACTION_TYPES.CLEAR_CART:
            if (restaurantCart) {
                const carts = state.carts.filter((cart) => cart.restaurantId !== action.restaurantId);
                return {
                    carts: [...carts],
                };
            }
            return state;
    }
    return state;
};

export default cartReducer;
