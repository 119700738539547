import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';

import { handleErrorsJson, ImgWithFallback } from '@bestelleck/shared';
import { filterGeoResults, removeDuplicatesByName, Place } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
    EmojiTransportation,
    Fastfood,
    InsertEmoticon,
    LocationOn,
    More,
    Payment,
    PhoneDisabled,
} from '@mui/icons-material';
import {
    Button,
    Card,
    CardContent,
    Snackbar,
    Typography,
    AlertTitle,
    Autocomplete,
    TextField,
    debounce,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Alert } from '../../components/Alert/Alert';
import { setPostalCode } from '../../redux/app/app.actions';
import { RootState } from '../../redux/store';
import { reverseGeoCode } from '../../services/geo.service';
import { geoEndpoint } from '../../util/constants';
import { useTrackView } from '../../util/tracking/trackPage';

import styles from './Entry.module.scss';

declare const Modernizr: any;

const Entry: React.FC = () => {
    const history = useHistory();
    const { trackEvent } = useMatomo();
    useTrackView('Home');

    let backgroundClasses = [styles.background];
    if (Modernizr.webp) {
        backgroundClasses = [styles.backgroundWebp, ...backgroundClasses];
    }

    const locationInfo = useSelector((state: RootState) => state.app.location, shallowEqual);

    const [error, setError] = useState({ message: '', isError: false });
    const [isLoading, setIsLoading] = useState(false);
    const [geoResults, setGeoResults] = useState<Place[]>([]);
    const [detailedSearch, setDetailedSearch] = useState('');
    const [geoSearch, setGeoSearch] = useState('');

    const onInputChange = useCallback(
        (newInputValue: string) => {
            setGeoSearch(newInputValue);
            trackEvent({
                action: 'Search address',
                category: 'Home',
                name: newInputValue,
            });
        },
        [trackEvent],
    );

    const debouncedChangeHandler = useMemo(() => debounce(onInputChange, 500), [onInputChange]);

    useEffect(() => {
        if (geoSearch !== '' && geoSearch.length > 2) {
            setGeoResults([]);
            setIsLoading(true);
            fetch(`${geoEndpoint}/search?q=${geoSearch}&limit=20&format=json&addressdetails=1`, {
                headers: { 'accept-language': 'de' },
            })
                .then(handleErrorsJson)
                .then((response) => response.json())
                .then((response: Place[]) => {
                    const filtered = filterGeoResults(response);
                    setGeoResults(removeDuplicatesByName(filtered));
                    setIsLoading(false);
                })
                .catch((error) => {
                    captureException(error);
                    setIsLoading(false);
                    setError({ message: 'Es ist ein Fehler aufgetreten, bitte versuche es erneut', isError: true });
                });
        }
    }, [geoSearch]);

    const dispatch: Dispatch<any> = useDispatch();

    const updateLocation = async (locationUpdate: Place) => {
        try {
            const geo = await reverseGeoCode({ latitude: locationUpdate.lat, longitude: locationUpdate.lon });
            if (geo.length === 0) {
                return false;
            }
            dispatch(
                setPostalCode({
                    place: locationUpdate,
                    deliveryPlaces: geo,
                }),
            );
            trackEvent({
                action: 'Select address',
                category: 'Home',
                name: locationUpdate.customDisplayName,
            });
            return true;
        } catch (error) {
            captureException(error);
        }
    };

    const validateLocation = async (locationUpdate?: Place | string): Promise<boolean> => {
        if (locationUpdate && typeof locationUpdate !== 'string') {
            const found = await updateLocation(locationUpdate);
            if (!found) return false;
            return true;
        }
        return false;
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        search();
    };

    const search = async (locationUpdate?: Place | string) => {
        if (detailedSearch === '') {
            history.push('/discover');
        } else if (locationUpdate) {
            const valid = await validateLocation(locationUpdate);
            if (valid) {
                history.push('/discover');
            } else {
                setError({ isError: true, message: 'Bitte gebe eine valide Adresse ein.' });
            }
        } else {
            const valid = await validateLocation(locationInfo.place);
            if (valid) {
                history.push('/discover');
            } else {
                setError({ isError: true, message: 'Bitte gebe eine valide Adresse ein.' });
            }
        }
    };

    return (
        <div className={styles.Entry}>
            <div className={backgroundClasses.join(' ')}>
                <div className={styles.container}>
                    <h3 id="title">
                        <p>Hunger?</p> Bestell bei dir ums <span>Eck</span>!
                    </h3>
                    <h4>Jetzt Entdecken:</h4>
                    <div className={styles.searchContainer}>
                        <form
                            className={styles.locationForm}
                            onSubmit={onSubmit}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <div className={styles.search}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    freeSolo
                                    color="secondary"
                                    loading={isLoading}
                                    autoHighlight
                                    loadingText={'Wird geladen...'}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.customDisplayName === value.customDisplayName;
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.place_id}>
                                                {option.customDisplayName}
                                            </li>
                                        );
                                    }}
                                    getOptionLabel={(option) => (option as Place).customDisplayName || ''}
                                    filterOptions={(x) => x}
                                    onChange={(selected, value) => {
                                        value = value as Place;
                                        if (value !== null) {
                                            search(value);
                                        }
                                    }}
                                    defaultValue={locationInfo.place}
                                    options={geoResults}
                                    className={styles.autoComplete}
                                    onInputChange={(event, newInputValue) => {
                                        setDetailedSearch(newInputValue);
                                        debouncedChangeHandler(newInputValue);
                                    }}
                                    handleHomeEndKeys
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                color="secondary"
                                                placeholder="Adresse, z.B. Hauptstraße 1"
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <Button
                                id="submitButton"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className={styles.searchButton}
                            >
                                Los gehts!
                            </Button>
                            <Snackbar
                                open={error.isError}
                                autoHideDuration={2000}
                                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                            >
                                <Alert severity="error">
                                    <AlertTitle>Fehler!</AlertTitle>
                                    {error.message}
                                </Alert>
                            </Snackbar>
                        </form>
                    </div>
                </div>
            </div>
            <div className={styles.instructionsContainer}>
                <h3>So funktioniert BestellEck:</h3>
                <div className={styles.instructions}>
                    <div className={styles.flexContainer}>
                        <Card className={styles.card}>
                            <CardContent>
                                <LocationOn style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Gib deine Postleitzahl an</Typography>
                                <Typography className={styles.text}>Finde alle Restaurants in der Umgebung</Typography>
                            </CardContent>
                        </Card>
                        <Card className={styles.card}>
                            <CardContent>
                                <EmojiTransportation
                                    style={{ fontSize: 40 }}
                                    className={styles.icon}
                                ></EmojiTransportation>
                                <Typography variant="h5">Abholung oder Lieferung</Typography>
                                <Typography className={styles.text}>
                                    Entscheide, ob du dein Essen liefern lassen möchtest oder es selbst beim Restaurant
                                    abholst.
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className={styles.flexContainer}>
                        <Card className={styles.card}>
                            <CardContent>
                                <Fastfood style={{ fontSize: 40 }} className={styles.icon}></Fastfood>
                                <Typography variant="h5">Bestellen</Typography>
                                <Typography className={styles.text}>
                                    Suche in der Speisekarte die gewünschten Gerichte, schicke deine Bestellung ab und
                                    bezahle bequem online.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={styles.card}>
                            <CardContent>
                                <InsertEmoticon style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Genießen!</Typography>
                                <Typography className={styles.text}>Lass dir dein Essen schmecken!</Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
            <div className={styles.app}>
                <div>
                    <div className={styles.appInfo}>
                        <h1>Die BestellEck App herunterladen</h1>
                        <h2>Jetzt Restaurants in deiner Nähe entdecken</h2>
                        <div>
                            <a
                                href="https://apps.apple.com/de/app/bestelleck/id1577427878"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src="/assets/ios.svg" alt="App Store" />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.slavisamarkovic.BestellEck"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <ImgWithFallback
                                    src="/assets/android.webp"
                                    fallback="/assets/android.png"
                                    alt="Google Play"
                                />
                            </a>
                        </div>
                    </div>
                    <ImgWithFallback
                        src="/assets/app.webp"
                        fallback="/assets/app.png"
                        alt=""
                        className={styles.phone}
                    />
                </div>
            </div>
            <div className={styles.whyContainer}>
                <h3>Warum BestellEck:</h3>
                <div className={styles.instructions}>
                    <div className={styles.flexContainer}>
                        <Card className={styles.card}>
                            <CardContent>
                                <LocationOn style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5" className={styles.header}>
                                    Lokal
                                </Typography>
                                <Typography className={styles.text}>
                                    Mit einer Bestellung unterstützt du die lokalen Restaurants, da wir keine Provision
                                    verlangen. Gerade in der aktuellen Zeit sind die Restaurants auf jeden Cent
                                    angewiesen.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={styles.card}>
                            <CardContent>
                                <PhoneDisabled style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Kein Telefonchaos</Typography>
                                <Typography>
                                    Verbindung abgebrochen, Leitung belegt, Bestellung falsch verstanden oder etwas
                                    vergessen? Kein Problem, mit BestellEck wird die Bestellung zum Komfort.
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className={styles.flexContainer}>
                        <Card className={styles.card}>
                            <CardContent>
                                <More style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Mehr Auswahl</Typography>
                                <Typography>
                                    Wir geben allen Restaurants in deiner Umgebung die Möglichkeit, Bestellungen online
                                    entgegenzunehmen. Dadurch ermöglichen wir dir überall zu bestellen, auch wenn es nur
                                    zum Abholen ist.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={styles.card}>
                            <CardContent>
                                <Payment style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Kontaktlos bezahlen</Typography>
                                <Typography>
                                    Gerade kein Bargeld dabei? Dein Lieblingsrestaurant nimmt keine Karte? Kein Problem,
                                    da du bei BestellEck online bezahlen kannst.
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Entry;
