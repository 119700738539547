import React, { useState } from 'react';

import styles from './Registration.module.scss';

const Registration: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const handleSubmit = (evt: any) => {
        evt.preventDefault();
        const payload = { contact: { fullName: name, email } };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        };

        fetch('http://localhost:3000/users', requestOptions).then((response) => response.json());
    };

    return (
        <div className={styles.Registration}>
            <div>
                <h3>Registration</h3>
                <form onSubmit={handleSubmit}>
                    <label>
                        Name
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                    </label>
                    <label>
                        E-Mail
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
            </div>
            <div>
                <h3>Shadow User</h3>
            </div>
        </div>
    );
};

export default Registration;
