import React from 'react';

import { GroupDescriptionComponent } from '@bestelleck/shared';
import {
    MenuStatus,
    Extra,
    MenuGroup,
    MenuItem,
    MenuTemplate,
    resolveTemplates,
    OrderType,
    ExtraGroup,
    MenuItemOption,
} from '@bestelleck/utils';
import { ErrorBoundary } from '@sentry/react';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from '../../../redux/store';
import { baseUrl } from '../../../util/constants';
import { getUniqueKey, isGroupAvailable } from '../../../util/utils';
import MenuItemComponent from '../MenuItem/MenuItem';

import styles from './MenuGroup.module.scss';

export type MenuGroupProps = {
    index?: number;
    group: MenuGroup;
    extras: Extra[];
    templates: MenuTemplate[];
    selectionGroups: (ExtraGroup | MenuItemOption)[];
    searchFilter: string;
    isOpen: boolean;
    showItemIdentifier: boolean;
    deliveryAvailable: boolean;
    updateMenuDisplay: (isVisible: boolean) => void;
};

const MenuGroupComponent: React.FC<MenuGroupProps> = ({
    extras,
    group,
    templates,
    isOpen,
    updateMenuDisplay,
    deliveryAvailable,
    selectionGroups,
    showItemIdentifier,
    searchFilter = '',
}) => {
    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);
    const isDelivery = orderType === OrderType.Delivery;
    const isAvailable = isGroupAvailable(group);

    const filteredItems = group.items.filter((item) => {
        if (item.status === MenuStatus.Inactive) {
            return false;
        }
        if (item.basePrice.delivery !== undefined && isDelivery) {
            return true;
        }
        if (item.basePrice.pickup !== undefined && !isDelivery) {
            return true;
        }
        return false;
    });
    let filteredAfterSearch: MenuItem[];

    if (searchFilter && searchFilter !== '') {
        filteredAfterSearch = filteredItems.filter((item) =>
            item.name.toUpperCase().includes(searchFilter.toUpperCase()),
        );
    } else {
        filteredAfterSearch = filteredItems;
    }

    const items = filteredAfterSearch.map((item, index) => {
        const resolvedItemWithTemplates = resolveTemplates(item, templates);

        if (resolvedItemWithTemplates) {
            return (
                <div key={getUniqueKey()} className={styles.menuItem}>
                    <ErrorBoundary>
                        <MenuItemComponent
                            index={index}
                            item={resolvedItemWithTemplates}
                            extras={extras}
                            isAvailable={isAvailable}
                            isOpen={isOpen}
                            showItemIdentifier={showItemIdentifier}
                            deliveryAvailable={deliveryAvailable}
                            selectionGroups={selectionGroups}
                        ></MenuItemComponent>
                    </ErrorBoundary>
                </div>
            );
        }
        return <></>;
    });
    if (items.length > 0) {
        updateMenuDisplay(true);
        return (
            <div className={styles.MenuGroup}>
                <div className={styles.groupHeader}>
                    <div className={styles.image}>
                        <img
                            className={isAvailable ? '' : styles.disabled}
                            alt=""
                            src={`${baseUrl}/image/${group.imageLink}?height=600`}
                        ></img>
                    </div>
                    <h3>{group.name}</h3>
                    {group.descriptions.length > 0 && <GroupDescriptionComponent descriptions={group.descriptions} />}
                </div>

                {items}
            </div>
        );
    }
    updateMenuDisplay(false);
    return <div></div>;
};

export default MenuGroupComponent;
