import { useEffect } from 'react';

import { useMatomo } from '@datapunt/matomo-tracker-react';
export function useTrackView(title: string): void {
    const { trackPageView, enableLinkTracking } = useMatomo();

    enableLinkTracking();
    useEffect(() => {
        trackPageView({ documentTitle: title });
    }, [title, trackPageView]);
}
